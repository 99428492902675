import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faCartArrowDown, faBriefcase, faChevronDown, faCheckCircle,faPrint,faShareAlt, faEye, faMapMarker, faPlus, faRocket, faStore } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Card, Dropdown } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';



export default () => {
    return (
        <>


            <Row>
                <Col xs={12} xl={4}>
                    <Card border="light" className="bg-white shadow-sm mb-4">
                        <Card.Body>
                            <h5 className="mb-4">Overview</h5>
                            <div className="userdetails"><h6>Job Title :</h6><span>April 30,2021</span></div>
                            <div className="userdetails"><h6>Experience :</h6><span>2yrs Exp</span></div>
                            <div className="userdetails"><h6>Vacancy :</h6><span>20</span></div>
                            <div className="userdetails"><h6>Job Type :</h6><span>Full-Type</span></div>
                            <div className="userdetails"><h6>Posted Date :</h6><span>13-6-2021</span></div>
                            <div className="userdetails"><h6>Last Apply Date :</h6><span>13-6-2021</span></div>
                            <div className="userdetails"><h6>Closed Date :</h6><span>12-8-2021</span></div>
                            <Row>
                                        <Col xs={12}>
                                        <Link to="/components/JobApplication"> <Button variant="danger" className="m-1">
                                                <FontAwesomeIcon icon={faCheckCircle} className="me-2" />Candidates
  </Button></Link>
  <Button variant="outline-danger" className="m-1">
                                                <FontAwesomeIcon icon={faShareAlt} className="me-2" /> Contact Now
  </Button>
                                        </Col>
                                    </Row>
                        </Card.Body>
                    </Card>
                </Col>

                <Col xs={12} xl={8}>
                    <Row>
                        <Col xs={12}>
                            <Card border="light" className="bg-white shadow-sm mb-4">
                                <Card.Body>
                                    <h5 >PHP Developer</h5>
                                    <small class="jobheading"> <FontAwesomeIcon icon={faBriefcase} />&nbsp;&nbsp;zerozilla</small>&nbsp;&nbsp;&nbsp; <small class="jobheading"> <FontAwesomeIcon icon={faMapMarker} />&nbsp;&nbsp;USA</small>&nbsp;&nbsp;&nbsp; <small class="jobheading"> <FontAwesomeIcon icon={faEye} />&nbsp;&nbsp;View</small>
                                    <hr></hr>
                                    <h5 >Description</h5>
                                    <ul className="listt">
                                        <li>We are Looking For a PHP Doveloper, who is must be familiar with the PHP fundamentals& PHP framwork. Experience with Laravel & mixs, Livewire</li>
                                        <li>Good knowledge of SQL and related databases, with a preference for those with MySQL experience.</li>
                                        <li>Excellent knowledge of the basic PHP 7 or web server exploits along with their solutions.</li>
                                        <li>Experience building or maintaining a CMS.</li>
                                        <li>Knowledge of MVC frameworks.</li>
                                        <li>A detailed understanding of database design and administration.</li>
                                        <li>The ability to integrate a variety of data sources and databases into a single system.</li>
                                    </ul>
                                    <hr></hr>
                                    <h5 >Description</h5>
                                    <Row>
                                        <Col xl={6} xs={6}>
                                            <div className="userdetails"><h6>Job Role :</h6><span>PHP Developer</span></div>
                                            <div className="userdetails"><h6>Role :</h6><span> Front-End Doveloper</span></div>
                                            <div className="userdetails"><h6>Min Salary :</h6><span>20,000</span></div>
                                            <div className="userdetails"><h6>Max Salary :</h6><span>30,000</span></div>
                                            <div className="userdetails"><h6>Job Tags :</h6><span>PHP, Laravel, HTML5, SCSS</span></div>
                                        </Col>
                                        <Col xl={6} xs={6}>
                                            <div className="userdetails"><h6>Job Experience :</h6><span>2yrs+</span></div>
                                            <div className="userdetails"><h6>Launges :</h6><span> Hindi, English</span></div>
                                            <div className="userdetails"><h6>Locality :</h6><span> USA, UK, India</span></div>
                                            <div className="userdetails"><h6>Eligibility :</h6><span>B.tech ,Any Graduate</span></div>
                                            <div className="userdetails"><h6>Company :</h6><span>Abcd corporation pvt ltd</span></div>
                                        </Col>
                                    </Row>
                                    <hr></hr>
                                    <div className="userdetails"><h6>Job ID: #8976542</h6><span>Posted By Company/ 12-01-2021</span></div>
                                    <hr></hr>
                                    <Row>
                                        <Col xs={12}>
                                            <Button variant="danger" className="m-1">
                                                <FontAwesomeIcon icon={faCheckCircle} className="me-2" /> Apply
  </Button><Button variant="warning" className="m-1">
                                                <FontAwesomeIcon icon={faShareAlt} className="me-2" /> Share Job
  </Button><Button variant="success" className="m-1">
                                                <FontAwesomeIcon icon={faPrint} className="me-2" /> Print
  </Button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};
