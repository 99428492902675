import React, { Component } from 'react';
import axios from 'axios';
import config from '../config.json';

const baseURL = config.REACT_APP_BASE_API_URL;
const endpointlogin = 'Admin/adminuser_login/';
const endpointstreamuploadview = "Job/streamCoursebulk/";
const endpointcourseuploadview = "Job/streamCoursebulk";
const endpointSkillsuploadview = "Job/skillbulk";
const endpointmarketinsightbulkview = "Job/marketInsightbulk";
const endpointviewgetjobfeilds = "Job/getAllJobFields"
const endpointviewaddstreamdetails = "Job/addStreamDetails"
const endpointviewstreambyfeildId = "Job/getAllStreamsByFieldId"
const endpointviewCourseList = "Job/getAllCoursesBySubStreamId";
const endpointviewskillsBulk = "Job/skillpageData";
const endpointviewsinglecourse = "Job/addStreamCourse";
const endpointviewaddskill = "Job/addskill"
const endpointviewupdateStreamDetails = "Job/updateStreamDetails";
const endpointviewremoveStream = "Job/removeStream";
const endpointviewupdateStreamCourseDetails = "Job/updateStreamCourseDetails";
const endpointviewupdateSkillsDetails ="Job/updateskill"
const endpointviewremoveStreamCourse = "Job/removeStreamCourse";
const endpointviewremoveskill = "Job/removeskill"
const endpointviewGetAllSkill = "Job/GetAllSkill"
const endpointviewGetAlcourse = "Job/getAllCourses";
const endpointviewDynamicCardList = "Job/getAllmarketInsightSkills";
const endpointviewaddDynamicCardList = "Job/marketInsightSkills";
const endpointviewdyanmiccardDetails = "Job/updatemarketInsightSkills"
const endpointviewremovedyanmiccard = "Job/removemarketInsightSkills";
const endpointviewgetallCourseByStreamID ="Job/getAllCoursesBySubStreamId";
const endpointviewMarketinsightDetailsByCourse = "Job/marketInsightDetails";
const endpointviewemployementIndustriesDetails ="Job/employementIndustries";
const endpointviewemgetallstatejobByCourseID = "Job/stateJob";
const endpointviewemgetallCityjobByCourseID = "Job/cityJob";
const endpointviewgetallemployementIndustries = "Job/getAllEmployementIndustries";
const endpointviewgetAllskillCoursesdetails = "Job/getAllskillCourses";
const endpointviewskillCoursesDetails = "Job/skillCoursesDetails";
const endpointviewremoveskillCoursesDetails ="Job/removeskillCoursesDetails";
const endpointviewupdateskillCoursesDetails = "Job/updateskillCoursesDetails";
const endpointviewdeleteempindustriesdetails = "Job/employementIndustries";
const endpointviewgetallMarketinsightDetailsbycourseId ="Job/getMarketInsightCourseDetails";
const endpointviewskillCoursesbulk ="Job/skillCoursesbulk";
const endpointviewupdatedcitydetails ="Job/updatecityJobs";
const endpointviewdeletecoursedetails = "Job/removecityJobs";
const endpointviewupdateddistrictdetails ="Job/updatedistrictJobs";
const endpointviewdeletedistrictdetails = "Job/removedistrictJobs";
const endpointviewdeleteskillsdetails = "Job/removemarketSkills";
const endpointviewupdatedskillsdetails = "Job/updatemarketSkills";
const endpointviewaddnewcitydetails = "Job/addcityJobs";
const endpointviewaddnewSkilsdetails = "Job/addmarketSkills";
const endpointviewaddnewDistrictdetails = "Job/adddistrictJobs";
const endpointviewaddnewemployeedetail = "Job/addEmployementArea";
const endpointviewremovenewemployeeareadetail = "Job/removeEmployementArea";
const endpointvieweditnewemployeeareadetail = "Job/updateEmployementArea";
const endpointviewaddnewdesigntiondetail = "Job/adddesignations";
const endpointvieweditnewdesigntiondetail ="Job/updatedesignations";
const endpointviewremovenewdesigntiondetail = "Job/removedesignations";
const endpointviewupdatejobsInIndiadetail ="Job/updatemarketInsightDetails";
const endpointviewgetAllCoursesBySubStreamId = "Job/getAllCoursesBySubStreamId"
const endpointviewskillCoursesBySkillId ="Job/skillCoursesBySkillId"
export const getToken = () => {
  return localStorage.getItem("token");

}
export const isLoggedIn = (token = null) => {
  if (!token) {
    token = getToken();
  }
}
class aunticationServices {
  Login(data) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'post',
      url: baseURL + endpointlogin,
      headers: headers,
      data: data,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  StreambulkUpload(data) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'post',
      url: baseURL + endpointstreamuploadview,
      headers: headers,
      data: data,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  CoursebulkUpload(data,id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'post',
      url: baseURL + endpointcourseuploadview+'/'+id,
      headers: headers,
      data: data,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  SkillCoursebulkUpload (data,id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'post',
      url: baseURL + endpointviewskillCoursesbulk+'/'+id,
      headers: headers,
      data: data,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  SkillsbulkUpload(data,id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'post',
      url: baseURL + endpointSkillsuploadview+'/'+id,
      headers: headers,
      data: data,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  
  marketinghtsbulkUpload(data,id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'post',
      url: baseURL + endpointmarketinsightbulkview+'/'+id,
      headers: headers,
      data: data,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  getalljobfeilds() {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'get',
      url: baseURL + endpointviewgetjobfeilds,
      headers: headers,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  
  addjobstreamdetails(data) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'post',
      url: baseURL + endpointviewaddstreamdetails,
      headers: headers,
      data : data,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  getallCourseList (id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'get',
      url: baseURL + endpointviewCourseList+'/'+id,
      headers: headers,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }

  getallstreambyfeildId(id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'get',
      url: baseURL + endpointviewstreambyfeildId+'/'+id,
      headers: headers,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  
  GetAllSkillBulk(id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'get',
      url: baseURL + endpointviewskillsBulk+'/'+id,
      headers: headers,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  
  addsingleCoursedetails(data) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'post',
      url: baseURL + endpointviewsinglecourse,
      headers: headers,
      data : data,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  
  addsingleaddskilldetails(data) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'post',
      url: baseURL + endpointviewaddskill,
      headers: headers,
      data : data,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  updateStreamDetails(data,id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'put',
      url: baseURL + endpointviewupdateStreamDetails+'/'+id,
      headers: headers,
      data : data,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  editnewemployeeareadetail (data,id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'put',
      url: baseURL + endpointvieweditnewemployeeareadetail+'/'+id,
      headers: headers,
      data : data,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  editnewdesigntiondetail (data,id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'put',
      url: baseURL + endpointvieweditnewdesigntiondetail+'/'+id,
      headers: headers,
      data : data,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  
  }
  addnewdesigntiondetail (data,id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'post',
      url: baseURL + endpointviewaddnewdesigntiondetail+'/'+id,
      headers: headers,
      data : data,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  deleteStreamDetails(id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'delete',
      url: baseURL + endpointviewremoveStream+'/'+id,
      headers: headers,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  removenewdesigntiondetail (data,id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'delete',
      url: baseURL + endpointviewremovenewdesigntiondetail+'/'+id,
      headers: headers,
      data:data,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  updatejobsInIndiadetail (data,id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'put',
      url: baseURL + endpointviewupdatejobsInIndiadetail+'/'+id,
      headers: headers,
      data:data,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  deletedyanmicCarddetails(id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'delete',
      url: baseURL + endpointviewremovedyanmiccard+'/'+id,
      headers: headers,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }

  
  updateCourseDetails(data,id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'put',
      url: baseURL + endpointviewupdateStreamCourseDetails+'/'+id,
      headers: headers,
      data : data,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }

  updateSkillsDetails(data,id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'put',
      url: baseURL + endpointviewupdateSkillsDetails+'/'+id,
      headers: headers,
      data : data,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  
  deleteCourseDetails(id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'delete',
      url: baseURL + endpointviewremoveStreamCourse+'/'+id,
      headers: headers,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  deleteSkillsDetails(id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'delete',
      url: baseURL + endpointviewremoveskill+'/'+id,
      headers: headers,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  removeskillCoursesDetails (id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'delete',
      url: baseURL + endpointviewremoveskillCoursesDetails+'/'+id,
      headers: headers,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  GetAllSkill() {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'get',
      url: baseURL + endpointviewGetAllSkill,
      headers: headers,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  getallMarketinsightDetails (id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'get',
      url: baseURL + endpointviewMarketinsightDetailsByCourse+'/'+id,
      headers: headers,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  getallemployementIndustriesDetails (id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'get',
      url: baseURL + endpointviewemployementIndustriesDetails+'/'+id,
      headers: headers,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  getallemployementIndustries () {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'get',
      url: baseURL + endpointviewgetallemployementIndustries,
      headers: headers,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  getallstatejobByCourseID (id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'get',
      url: baseURL + endpointviewemgetallstatejobByCourseID+'/'+id,
      headers: headers,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  getallCityJobByCourseID (id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'get',
      url: baseURL + endpointviewemgetallCityjobByCourseID+'/'+id,
      headers: headers,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  getallCourse() {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'get',
      url: baseURL + endpointviewGetAlcourse,
      headers: headers,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  getDynamicCardListDetails () {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'get',
      url: baseURL + endpointviewDynamicCardList,
      headers: headers,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  getallCourseByStreamID (id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'get',
      url: baseURL + endpointviewgetallCourseByStreamID+'/'+id,
      headers: headers,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  adddynamicCarddetails (data) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'post',
      url: baseURL + endpointviewaddDynamicCardList,
      headers: headers,
      data : data,
    };
    return axios(config)
      .then((response) => {
        return response;
        
      })
  }
  deleteempindustriesdetails (data,id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'delete',
      url: baseURL + endpointviewdeleteempindustriesdetails+'/'+id,
      headers: headers,
      data : data,
    };
    return axios(config)
      .then((response) => {
        return response;
        
      })
  }
  updateempindustriesdetails (data,id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'put',
      url: baseURL + endpointviewdeleteempindustriesdetails+'/'+id,
      headers: headers,
      data : data,
    };
    return axios(config)
      .then((response) => {
        return response;
        
      })
  }
  updatedcitydetails (data,id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'put',
      url: baseURL + endpointviewupdatedcitydetails+'/'+id,
      headers: headers,
      data : data,
    };
    return axios(config)
      .then((response) => {
        return response;
        
      })
  }
  updateddistrictdetails (data,id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'put',
      url: baseURL + endpointviewupdateddistrictdetails+'/'+id,
      headers: headers,
      data : data,
    };
    return axios(config)
      .then((response) => {
        return response;
        
      })
  }
  updatedskillsdetails (data,id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'put',
      url: baseURL + endpointviewupdatedskillsdetails+'/'+id,
      headers: headers,
      data : data,
    };
    return axios(config)
      .then((response) => {
        return response;
        
      })
  }
  addnewcitydetail(data,id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'post',
      url: baseURL + endpointviewaddnewcitydetails+'/'+id,
      headers: headers,
      data : data,
    };
    return axios(config)
      .then((response) => {
        return response;
        
      }) 
  }
  addnewSkillsdetail (data,id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'post',
      url: baseURL + endpointviewaddnewSkilsdetails+'/'+id,
      headers: headers,
      data : data,
    };
    return axios(config)
      .then((response) => {
        return response;
        
      }) 
  }
  addnewDistrictdetail (data,id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'post',
      url: baseURL + endpointviewaddnewDistrictdetails+'/'+id,
      headers: headers,
      data : data,
    };
    return axios(config)
      .then((response) => {
        return response;
        
      })  
  }
  addnewemployeedetail (data,id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'post',
      url: baseURL + endpointviewaddnewemployeedetail+'/'+id,
      headers: headers,
      data : data,
    };
    return axios(config)
      .then((response) => {
        return response;
        
      })  
  }
  removenewemployeeareadetail (data,id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'delete',
      url: baseURL + endpointviewremovenewemployeeareadetail+'/'+id,
      headers: headers,
      data : data,
    };
    return axios(config)
      .then((response) => {
        return response;
        
      })
  }
  deletecoursedetails (data,id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'delete',
      url: baseURL + endpointviewdeletecoursedetails+'/'+id,
      headers: headers,
      data : data,
    };
    return axios(config)
      .then((response) => {
        return response;
        
      })
  }
  deleteskillsdetails (data,id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'delete',
      url: baseURL + endpointviewdeleteskillsdetails+'/'+id,
      headers: headers,
      data : data,
    };
    return axios(config)
      .then((response) => {
        return response;
        
      })
  }
  deletedistrictdetails (data,id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'delete',
      url: baseURL + endpointviewdeletedistrictdetails+'/'+id,
      headers: headers,
      data : data,
    };
    return axios(config)
      .then((response) => {
        return response;
        
      })
  }
  updateDynamiccardDetails(data,id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'put',
      url: baseURL + endpointviewdyanmiccardDetails+'/'+id,
      headers: headers,
      data : data,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  updateskillCoursesDetails (data,id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'put',
      url: baseURL + endpointviewupdateskillCoursesDetails+'/'+id,
      headers: headers,
      data : data,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  getAllskillCoursesdetails () {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'get',
      url: baseURL + endpointviewgetAllskillCoursesdetails,
      headers: headers,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  } 
  addskillCoursesDetails (data) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'post',
      url: baseURL + endpointviewskillCoursesDetails,
      headers: headers,
      data : data
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  getallMarketinsightDetailsbycourseId (id){
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'get',
      url: baseURL + endpointviewgetallMarketinsightDetailsbycourseId+'/'+id,
      headers: headers,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  getallCourseStreamIddetails (id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'get',
      url: baseURL + endpointviewgetAllCoursesBySubStreamId+'/'+id,
      headers: headers,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  GetAllSkillByCourseId (id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'get',
      url: baseURL + endpointviewskillsBulk+'/'+id,
      headers: headers,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  getallemployementIndustriesCourseID (id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'get',
      url: baseURL + endpointviewemployementIndustriesDetails+'/'+id,
      headers: headers,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
  getDynamicCardListDetailsCourseID (id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'get',
      url: baseURL + endpointviewaddDynamicCardList+'/'+id,
      headers: headers,
    };
    return axios(config)
      .then((response) => {
        return response;
      }) 
  }
  getAllskillCoursesdetailsbycourseId(id) {
    const headers = {
      'Content-Type': 'application/json',
    };
    var config = {
      method: 'get',
      url: baseURL + endpointviewskillCoursesBySkillId+'/'+id,
      headers: headers,
    };
    return axios(config)
      .then((response) => {
        return response;
      })
  }
}
const auntacationInstance = new aunticationServices();

export default auntacationInstance;