
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faChartLine, faCloudUploadAlt, faEdit, faTrashAlt, faDownload, faThumbsUp, faPlus, faRocket, faTasks, faUserShield, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Span, Dropdown, ButtonGroup, Card, Nav, Table, Form } from '@themesberg/react-bootstrap';
import { PageVisitsTable } from "../../components/Tables";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Api from '../../service/api';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
class dashboard extends React.Component {
    constructor() {
        super();
        this.state = {
            readOnly: true,
            arrayOfDivs: [],
            divID: 1,
            arrayOfDivs1: [],
            divID1: 1,
            source:'',
            errorTextsource:'',
            name:'',
            errorTextname:'',
            feeType:'',
            skillId:'',
            errorTextskillId:"",
            errorTextfeeType:'',
            courseUrl:'',
            errorTextcourseUrl:'',
            rating:'',
            errorTextrating:'',
            SkillCourses:[],
            SkillsList:[],
        }
    }
    componentDidMount() {
        this.getDynamicCardList()
        this.getallSkillsList()
        this.getJobsFeildList()
    }
    getJobsFeildList = () => {
        Api.getalljobfeilds().then(
            res => {
                if (res.data.status == "success") {
                    console.log(res.data.data, "resss")
                    this.setState({
                        JobFeilds: res.data.data,
                    })
                } else {
                    console.log("error")
                }
            })
    }
    getDynamicCardList = () => {
        Api.getAllskillCoursesdetails().then(
            res => {
                if (res.data.status == "success") {
                    console.log(res.data.data, "resss")
                    this.setState({
                        SkillCourses: res.data.data,
                    })
                } else {
                    console.log("error")
                }
            })
    }
    getallSkillsList = () => {
        Api.GetAllSkill().then(
            res => {
                if (res.data.status == "success") {
                    console.log(res.data.data, "resss")
                    this.setState({
                        SkillsList: res.data.data,
                    })
                } else {
                    console.log("error")
                }
            })
    }
    onChangeseletectCourse = (event) => {
        console.log(event.target.value)
        let feildVal = event.target.value;
        if (feildVal === "") {
            this.setState({
                errorTextskillId: "Enter Email",
                isValid: false
            })
        }
        else {
            this.setState({
                errorTextskillId: "",
                skillId: event.target.value,
                isValid: true
            })
            this.getCourseListbycourseId(event.target.value)
        }
    }
    onChangeselectskills =(event)=> {
        console.log(event.target.value)
        let feildVal = event.target.value;
        if (feildVal === "") {
            this.setState({
                errorTextskillId: "Enter Email",
                isValid: false
            })
        }
        else {
            this.setState({
                errorTextskillId: "",
                skillssId: event.target.value,
                isValid: true
            })
            this.getDynamicCardListbyskillsid(event.target.value)
        } 
    }
    getDynamicCardListbyskillsid = (id) => {
        Api.getAllskillCoursesdetailsbycourseId(id).then(
            res => {
                if (res.data.status == "success") {
                    console.log(res.data.data, "resss")
                    this.setState({
                        SkillCourses: res.data.data,
                    })
                } else {
                    console.log("error")
                }
            })
    }
    getCourseListbycourseId (e) {
        Api.GetAllSkillByCourseId(e).then(
            res => {
                if (res.data.status == "success") {
                    console.log(res.data.data, "resss")
                    this.setState({
                        SkillsList: res.data.data,
                    })
                } else {
                    console.log("error")
                }
            })  
    }

    onchnageofname = (event) => {
        console.log(event.target.value)
        let RelatedSkillVal = event.target.value;
        if (RelatedSkillVal === "") {
            this.setState({
                errorTextname: "Enter Email",
                isValid: false
            })
        }
        else {
            this.setState({
                errorTextname: "",
                name: event.target.value,
                isValid: true
            })
        }
    }
    onchnageofsource = (event) => {
        console.log(event.target.value)
        let softSkillVal = event.target.value;
        if (softSkillVal === "") {
            this.setState({
                errorTextsource: "Enter Email",
                isValid: false
            })
        }
        else {
            this.setState({
                errorTextsource: "",
                source: event.target.value,
                isValid: true
            })
        }
    }

    onchnageofcourseUrl = (event) => {
        console.log(event.target.value)
        let descriptionVal = event.target.value;
        if (descriptionVal === "") {
            this.setState({
                errorTextcourseUrl: "Enter Email",
                isValid: false
            })
        }
        else {
            this.setState({
                errorTextcourseUrl: "",
                courseUrl: event.target.value,
                isValid: true
            })
        }
    }

    onClickButton = (e) => {
        this.setState({ openModal: true })
    }
    onCloseModal = () => {
        this.setState({ openModal: false })
    }
    onClickButton1 = (e) => {
        console.log(e)
        this.setState({
            openModal1: true,
            editabledetails: e,
        })
    }
    onCloseModal1 = () => {
        this.setState({ openModal1: false })
    }
    onClickButton2 = (e) => {
        this.setState({
            openModal2: true,
        })
    }
    onCloseModal2 = () => {
        this.setState({ openModal2: false })
    }
    onchnageofrating = (event) => {
        console.log(event.target.value)
        let feildVal = event.target.value;
        if (feildVal === "") {
            this.setState({
                errorTextrating: "Enter Email",
                isValid: false
            })
        }
        else {
            this.setState({
                errorTextrating: "",
                rating: event.target.value,
                isValid: true
            })
        }
    }

    onChangeofname = (event) => {
        let nameval = event.target.value;
        if (nameval === "") {
            this.setState({
                errorTextname: "Enter Password ",
                isValid: false
            })
        }
        else {
            this.setState({
                errorTextname: "",
                name: event.target.value,
                isValid: true
            })
        }
    }
    validateInput() {
        let errorTextsoftSkill = "";
        let errorTextRelatedSkill = "";
        let isValid = true;
        if (this.state.softSkill === 0) {
            errorTextsoftSkill = 'Enter Password';
            isValid = false;
        }
        if (this.state.RelatedSkill === 0) {
            errorTextRelatedSkill = 'Enter Password';
            isValid = false;
        }

        this.setState({
            ...this.state,
            errorTextsoftSkill,
            errorTextRelatedSkill,

        })
        return isValid;
    }
    submitForm(event) {
        console.log("sdkjf")
        if (this.validateInput()) {
            let data = {
                "name": this.state.name,
                "source": this.state.source,
                "feeType": this.state.feeType,
                "rating": this.state.rating,
                "courseUrl": this.state.courseUrl,
                "skillId": this.state.skillId,
            }
            Api.addskillCoursesDetails(data).then(
                res => {
                    console.log(res.data, "res")
                    if (res.data.status == "success") {
                        toast.success('Dyanmic card Details added Successfully', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        })
                        this.onCloseModal();
                        window.location.reload();
                        event.preventDefault();
                    } else {
                        console.log("error",);
                        toast.error('Something Went Wrong', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        })
                    }
                }

            ).then(error => {
                console.log(error, "error")
            })
                .catch(error => {
                    console.log(error, "error")
                    toast.error('Something Went Wrong', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });

        }
    }
    onchnageoffeeType = (event) => {
        console.log(event.target.value)
        let descriptionVal = event.target.value;
        if (descriptionVal === "") {
            this.setState({
                errorTextfeeType: "Enter Email",
                isValid: false
            })
        }
        else {
            this.setState({
                errorTextfeeType: "",
                feeType: event.target.value,
                isValid: true
            })
        }
    }
    // onChangeOfeditName = (event) => {
    //     let nameVal = event.target.value;
    //     if (nameVal === "") {
    //         this.setState({
    //             onNameErrorText: "Enter Organization Name",
    //             isValid: false
    //         })
    //     }
    //     else {
    //         this.setState({
    //             onNameErrorText: "",
    //             name: event.target.value,
    //             isValid: true
    //         })
    //     }
    // }
    Submiteditdetails = () => {
        let data = {
            "name": this.state.name ? this.state.name : this.state.editabledetails.name,
            "source": this.state.source ? this.state.source : this.state.editabledetails.source,
            "feeType": this.state.feeType ? this.state.feeType : this.state.editabledetails.feeType,
            "rating": this.state.rating ? this.state.rating : this.state.editabledetails.rating,
            "courseUrl": this.state.courseUrl ? this.state.courseUrl : this.state.editabledetails.courseUrl,
            "skillId": this.state.skillId ? this.state.skillId : this.state.editabledetails.skillId,
        }
        Api.updateskillCoursesDetails(data, this.state.editabledetails._id).then(
            res => {
                console.log(res.data, "res")
                if (res.data.status == "success") {
                    toast.success('Stream Details Update Successfully', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    this.onCloseModal()
                    window.location.reload();
                    this.getJobsFeildList()
                    // event.preventDefault();
                } else {
                    console.log("error",);
                    // this.onCloseModal();
                    // this.getJobsFeildList()
                    // window.location.reload();

                    toast.error('Something Went Wrong', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }

        ).then(error => {
            console.log(error, "error")
        })
            .catch(error => {
                console.log(error, "error")
                // this.onCloseModal();
                // this.getJobsFeildList()
                // window.location.reload();
                toast.error('Something Went Wrong', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
        console.log(data)
    }
    submit = (e) => {
        confirmAlert({
            title: 'Delete',
            message: 'Are you sure want to Delete',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.deleteStream(e)
                },
                {
                    label: 'No',
                    onClick: () => alert('Click No')
                }
            ]
        });
    };
    deleteStream = (id) => {
        console.log(id)
        Api.removeskillCoursesDetails(id._id).then(
            res => {
                console.log(res.data, "res")
                if (res.data.status == "success") {
                    toast.success('Stream Details Deleted Successfully', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    this.onCloseModal()
                    window.location.reload();
                    this.getJobsFeildList()
                } else {
                    console.log("error",);
                    toast.error('Something Went Wrong', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }

        ).then(error => {
            console.log(error, "error")
        })
            .catch(error => {
                console.log(error, "error")
                // toast.error('Something Went Wrong', {
                //     position: "top-right",
                //     autoClose: 5000,
                //     hideProgressBar: true,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                // });
            });
    }
    onClickexcelupload = (e) => {
        // console.log(e.target.files[0])
        var fd = new FormData;
        fd.set("file", this.state.file)
        Api.SkillCoursebulkUpload(fd,this.state.SelectfieldId).then(
            res => {
                console.log(res.data.response, "res")
                if (res.data.response.responseStatus == 1) {
                    toast.success(res.data.response.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                } else {
                    console.log("error",)
                    toast.error('Something Went Wrong', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }

        ).then(error => {
            console.log(error, "error")
        })
            .catch(error => {
                console.log(error, "error")
                toast.error('Something Went Wrong', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    }
    onClickofbulkupload = (e) => {
        console.log(e.target.files[0])
        this.setState({
            errorTextfile: "",
            file: e.target.files[0],
            isValid: true
        })
    }
    onChangeseletectOffeild = (event) => {
        console.log(event.target.value)
        let feildVal = event.target.value;
        if (feildVal === "") {
            this.setState({
                errorTextfieldId: "Enter Email",
                isValid: false
            })
        }
        else {
            this.setState({
                errorTextfieldId: "",
                SelectfieldId: event.target.value,
                isValid: true
            })
            this.getStreamByFeildList(event.target.value)
        }
    }
    getStreamByFeildList = (id) => {
        Api.getallstreambyfeildId(id).then(
            res => {
                console.log(res.data.data, "resList")
                if (res.data.status == "success") {
                    console.log(res.data.data, "resss")
                    this.setState({
                        StreamByFeildsId: res.data.data,
                    })
                } else {
                    console.log("error")
                }
            })
    }
    onChangeseletectstream = (event) => {
        console.log(event.target.value)
        let feildVal = event.target.value;
        if (feildVal === "") {
            this.setState({
                errorTextfieldId: "Enter Email",
                isValid: false
            })
        }
        else {
            this.setState({
                errorTextfieldId: "",
                SelectfieldId: event.target.value,
                streamId: event.target.value,
                isValid: true
            })
            this.getStreanBycourse(event.target.value)
        }
    }
    getStreanBycourse = (e) => {
        Api.getallCourseList(e).then(
            res => {
                if (res.data.status == "success") {
                    console.log(res.data.data, "resss")
                    this.setState({
                        CourseList: res.data.data,
                    })
                    this.getJobsFeildList()
                } else {
                    console.log("error")
                }
            })
    }
    render() {
        return (
            <>
                <Card border="light" className="shadow-sm">
                    <Card.Header>
                        <Row className="align-items-center">
                            <Col>
                                <h5>Training Courses</h5>
                            </Col>
                            <Col>
                                <Form >
                                    <Form.Group >
                                        <Form.Select defaultValue="0" onChange={this.onChangeseletectOffeild}>
                                            <option value="1">Selecte Feild List </option>
                                            {this.state.JobFeilds?.map((feild, index) => (<>   <option value={feild._id}>{feild.name}</option> </>))}
                                        </Form.Select>
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col>
                                <Form>
                                    <Form.Group >
                                        <Form.Select defaultValue="0" onChange={this.onChangeseletectstream}>
                                            <option value="1">Selecte Stream </option>
                                            {this.state.StreamByFeildsId?.map((stream, index) => (<>   <option value={stream._id}>{stream.name}</option> </>))}
                                        </Form.Select>
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col>
                                <Form>
                                    <Form.Group >
                                        <Form.Select defaultValue="0" onChange={this.onChangeseletectCourse}>
                                            <option value="1">Selecte Course </option>
                                            {this.state.CourseList?.map((stream, index) => (<>   <option value={stream._id}>{stream.name}</option> </>))}
                                        </Form.Select>
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col>
                                <Form>
                                    <Form.Group >
                                        <Form.Select defaultValue="0" onChange={this.onChangeselectskills}>
                                            <option value="1">Selecte Skills </option>
                                            {this.state.SkillsList?.map((skills, index) => (<>   <option value={skills._id}>{skills.name}</option> </>))}
                                        </Form.Select>
                                    </Form.Group>
                                </Form>
                            </Col>
                            
                            <Col className="text-end">
                                <React.Fragment>
                                    <div className="d-xl-flex align-items-center">
                                        <div className="file-field">
                                            <div className="d-flex justify-content-xl-center ms-xl-3">
                                                <Button onClick={this.onClickButton2} variant="outline-success" className="m-1 d-flex">
                                                    {/* <input type="file" accept=".xlsx, .xls, .csv" />
                                                    <div className="d-md-block text-start">
                                                        <div className="text-gray small"> */}
                                                            Bulk Upload
                                                            {/* </div>
                                                    </div> */}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            </Col>
                            <Col className="text-end">
                                <Button variant="secondary" onClick={() => this.onClickButton()} size="sm">Add Courses</Button>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Table responsive className="align-items-center table-flush">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Skill Name</th>
                                <th scope="col">Name</th>
                                <th scope="col">Source</th>
                                <th scope="col">Fee Type</th>
                                <th scope="col">Course Url</th>
                                <th scope="col">Rating</th>
                                <th scope="col">Status</th>
                                <th scope="col">Active</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.SkillCourses.map((card, index) => (<>
                                <tr>
                                    <td>{card?.skillId.name}</td>
                                    <td>{card?.name}</td>
                                    <td>{card?.source}</td>
                                    <td>{card?.feeType}</td>
                                    <td>{card?.courseUrl}</td>
                                    <td>{card?.rating}</td>
                                    <td>Active</td>
                                    <td><FontAwesomeIcon onClick={() => this.onClickButton1(card)} icon={faEdit} cursor="pointer" className="me-2" /> <FontAwesomeIcon onClick={() => this.submit(card)} icon={faTrashAlt} className="me-2" /></td>
                                </tr>
                            </>))}
                        </tbody>
                    </Table>
                </Card>
                <div>
                    <Modal open={this.state.openModal} onClose={this.onCloseModal}>
                        <h4 className="shareprofile text-center">Add Streams</h4>
                        <Form onSubmit={(e) => { e.preventDefault(); this.submitForm(e) }}>
                            <Form.Group className="mb-3">
                                <Form.Label>Course </Form.Label>
                                <Form.Select defaultValue="0" onChange={this.onChangeseletectCourse}>
                                    <option value="1">Select Course </option>
                                    {this.state.SkillsList?.map((feild, index) => (<>   <option value={feild._id}>{feild.name}</option> </>))}
                                </Form.Select>
                            </Form.Group>

                            
                            <Form.Group className="mb-3">
                                <Form.Label>name</Form.Label>
                                <Form.Control type="text" onChange={this.onchnageofname} placeholder="Enter Name" />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Source </Form.Label>
                                <Form.Control type="text" onChange={this.onchnageofsource} placeholder="Enter Source" />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Course Type </Form.Label>
                                <Form.Select defaultValue="0" onChange={this.onchnageoffeeType}>
                                    <option value="1">Select Type </option>
                                    <option value="paid">Paid </option>
                                    <option value="free">Free </option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Rating </Form.Label>
                                <Form.Select defaultValue="0" onChange={this.onchnageofrating}>
                                    <option value="1">Select Rating </option>
                                    <option value="1">1 </option>
                                    <option value="2">2 </option>
                                    <option value="3">3 </option>
                                    <option value="4">4 </option>
                                    <option value="5">5 </option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Course Url</Form.Label>
                                <Form.Control type="text" onChange={this.onchnageofcourseUrl} placeholder="Enter Course Url" />
                            </Form.Group>

                            <Col className="text-center">
                                <Button variant="secondary text-center" type="submit" size="md"> Submit</Button>
                            </Col>
                        </Form>
                    </Modal>
                </div>

                <div>
                    <Modal open={this.state.openModal1} onClose={this.onCloseModal1}>
                        <h4 className="shareprofile text-center">Edit Streams</h4>
                        <Form>
                        <Form.Group className="mb-3">
                                <Form.Label>name</Form.Label>
                                <Form.Control type="text" defaultValue={this.state.editabledetails?.name ? this.state.editabledetails?.name : this.state.name} onChange={this.onchnageofname} placeholder="Enter Name" />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Source </Form.Label>
                                <Form.Control type="text" defaultValue={this.state.editabledetails?.source ? this.state.editabledetails?.source : this.state.source} onChange={this.onchnageofsource} placeholder="Enter Source" />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Course Type </Form.Label>
                                <Form.Select ddefaultValue={this.state.editabledetails?.feeType ? this.state.editabledetails?.feeType : this.state.feeType} onChange={this.onchnageoffeeType}>
                                    <option value="1">Select Type </option>
                                    <option value="paid">Paid </option>
                                    <option value="free">Free </option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Rating </Form.Label>
                                <Form.Select defaultValue={this.state.editabledetails?.rating ? this.state.editabledetails?.rating : this.state.rating} onChange={this.onchnageofrating}>
                                    <option value="1">Select Rating </option>
                                    <option value="1">1 </option>
                                    <option value="2">2 </option>
                                    <option value="3">3 </option>
                                    <option value="4">4 </option>
                                    <option value="5">5 </option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Course Url</Form.Label>
                                <Form.Control type="text" defaultValue={this.state.editabledetails?.courseUrl ? this.state.editabledetails?.courseUrl : this.state.courseUrl} onChange={this.onchnageofcourseUrl} placeholder="Enter Course Url" />
                            </Form.Group>

                            <Col className="text-center">
                                <Button variant="secondary text-center" type="submit" onClick={this.Submiteditdetails} size="md"> Submit</Button>
                            </Col>
                        </Form>
                    </Modal>
                    <ToastContainer />
                </div>
                <div>
                    <Modal open={this.state.openModal2} onClose={this.onCloseModal2}>
                        <h4 className="shareprofile text-center">Bulk Upload Course</h4>
                        <Form>
                            <Form.Group >
                                <Form.Label>Select Feild </Form.Label>
                                <Form.Select defaultValue="0" onChange={this.onChangeseletectOffeild}>
                                    <option value="1">Selecte Feild List </option>
                                    {this.state.JobFeilds?.map((feild, index) => (<>   <option value={feild._id}>{feild.name}</option> </>))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Bulk Upload</Form.Label>
                                <Form.Control type="file" accept=".xlsx, .xls, .csv" onChange={this.onClickofbulkupload}></Form.Control>
                            </Form.Group>
                            <Col className="text-center">
                                <Button variant="secondary text-center" onClick={this.onClickexcelupload} size="md"> Submit</Button>
                            </Col>
                        </Form>
                    </Modal>
                    <ToastContainer />
                </div>
            </>
        );
    };
};
export default dashboard;