
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faChartLine, faCloudUploadAlt, faEdit, faTrashAlt, faPlus, faRocket, faTasks, faUserShield, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Span, Dropdown, ButtonGroup, Card, Nav, Table, Form } from '@themesberg/react-bootstrap';
import { CounterWidget, CircleChartWidget, BarChartWidget, TeamMembersWidget, ProgressTrackWidget, RankingWidget, SalesValueWidget, SalesValueWidgetPhone, AcquisitionWidget } from "../../components/Widgets";
import { PageVisitsTable } from "../../components/Tables";
import { trafficShares, totalOrders } from "../../data/charts";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Api from '../../service/api';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
class dashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      readOnly: true,
      arrayOfDivs: [],
      divID: 1,
      arrayOfDivs1: [],
      divID1: 1,
      SelectcourseID: '',
      marketinsightdetails: [],
      emplyeeindustriesDetails: [],
      getallstatejobdetails: [],
      areaupdatesdetails: [],
      getallCityjobdetails: [],
      editabledetailsforcitywise: [],
      editabledetailsfordistrictwise: [],
      updatedjobsindia: [],
      designtionupdatesdetails: [],
      editabledetailsforSkillswise: [],
      CityJobs: [],
      districtJobs: [],
      marketSkills: [],
      errorTextvalue: "",
      errorTextName: "",
      selectedfeildID: '',
      value: "",
      newValue: "",
      newName: '',
      name: "",
      JobFeilds: [],
    }
    this._click = this._click.bind(this);
  }
  componentDidMount() {
    this.getStreamByFeildList()
    this.getJobsFeildList()
  }
  getJobsFeildList = () => {
    Api.getalljobfeilds().then(
      res => {
        if (res.data.status == "success") {
          console.log(res.data.data, "resss")
          this.setState({
            JobFeilds: res.data.data,
            SelectfieldId: res.data.data[0]._id
          })
          this.getStreamByFeildList(res.data.data[0]._id)
        } else {
          console.log("error")
        }
      })
  }
  removeElement = (e, id) => {
    this.setState((prevState) => {
      let newArr = prevState.arrayOfDivs.filter((item) => item.id !== id);
      return { arrayOfDivs: newArr };
    });
  };
  addElement = () => {
    this.setState((prevState) => {
      return {
        arrayOfDivs: [
          ...prevState.arrayOfDivs,
          { name: '', id: prevState.divID },
        ],
      };
    });
    this.setState((prevState) => {
      return { divID: prevState.divID + 1 };
    });
  };
  addElement1 = () => {
    this.setState((prevState) => {
      return {
        arrayOfDivs1: [
          ...prevState.arrayOfDivs1,
          { name: '', id: prevState.divID1 },
        ],
      };
    });
    this.setState((prevState) => {
      return { divID1: prevState.divID1 + 1 };
    });
  };

  _click() {
    this.setState(prevState => ({ readOnly: !prevState.readOnly }))
  }
  onClickButton = (e) => {
    this.setState({ openModal: true })
  }
  onCloseModal = () => {
    this.setState({ openModal: false })
  }
  onClickButton1 = (e, f) => {
    console.log(e, "asa")
    this.setState({ openModal1: true })
  }
  onCloseModal1 = () => {
    this.setState({ openModal1: false })
  }
  onClickButton2 = (e) => {
    this.setState({
      openModal2: true,
      editabledetailsforSkillswise: e
    })
  }
  onCloseModal2 = () => {
    this.setState({ openModal2: false })
  }
  onClickButton3 = (e) => {
    this.setState({ openModal3: true })
  }
  onCloseModal3 = () => {
    this.setState({ openModal3: false })
  }
  onClickButton4 = (e) => {
    console.log(e, "er")
    this.setState({
      openModal4: true,
      editabledetailsforcitywise: e
    })
  }
  onCloseModal4 = () => {
    this.setState({ openModal4: false })
  }
  onClickButton5 = (e) => {
    this.setState({
      openModal5: true,
      editabledetailsfordistrictwise: e
    })
  }
  onCloseModal5 = () => {
    this.setState({ openModal5: false })
  }
  onClickButton6 = (e) => {
    this.setState({
      openModal6: true,
    })
  }
  onCloseModal6 = () => {
    this.setState({ openModal6: false })
  }
  onClickButtonarea = (e) => {
    this.setState({
      openModal7: true,
      areaupdatesdetails: e
    })
  }
  onCloseModal7 = () => {
    this.setState({ openModal7: false })
  }
  onClickButtondesigntion = (e) => {
    this.setState({
      openModal9: true,
      designtionupdatesdetails: e
    })
  }
  onCloseModal9 = () => {
    this.setState({ openModal9: false })
  }
  onCloseModal8 = () => {
    this.setState({ openModal8: false })
  }
  onClickButton8 = (e) => {
    this.setState({
      openModal8: true,
    })
  }
  onCloseModal10 = () => {
    this.setState({ openModal10: false })
  }
  onClickButton10 = (e) => {
    this.setState({
      openModal10: true,
      updatedjobsindia: e,
    })
  }
  onCloseModal11 = () => {
    this.setState({ openModal11: false })
  }
  onClickButton11 = (e) => {
    this.setState({
      openModal11: true,
    })
  }
  onClickofbulkupload = (e) => {
    console.log(e.target.files[0])
    this.setState({
        errorTextfile: "",
        file: e.target.files[0],
        isValid: true
    })
}
  onClickexcelupload = (e) => {
    console.log(this.state.selectedfeildID)
    var fd = new FormData;
    fd.set("file", this.state.file)
    Api.marketinghtsbulkUpload(fd, this.state.selectedfeildID).then(
      res => {
        console.log(res.data.response, "res")
        if (res.data.response.responseStatus == 1) {
          toast.success(res.data.response.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          console.log("error",)
          toast.error('Something Went Wrong', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      }

    ).then(error => {
      console.log(error, "error")
    })
      .catch(error => {
        console.log(error, "error")
        toast.error('Something Went Wrong', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }
  feilddetails = (e) => {
    console.log(e)
    this.setState({
      selectedFeild: e.name,
      selectedfeildID: e._id
    })
    this.getStreamByFeildList(e._id)
  }
  getStreamByFeildList = (id) => {
    Api.getallstreambyfeildId(id ? id : "6278f9134b4b83a9af1933eb").then(
      res => {
        console.log(res.data.data, "resList")
        if (res.data.status == "success") {
          console.log(res.data.data, "streambyfeildList")
          this.setState({
            StreamByFeildsId: res.data.data,
          })
        } else {
          console.log("error")
        }
      })
  }
  onChangeseletectOffeild = (event) => {
    console.log(event.target.value)
    let feildVal = event.target.value;
    if (feildVal === "") {
      this.setState({
        errorTextfieldId: "Enter Email",
        isValid: false
      })
    }
    else {
      this.setState({
        errorTextfieldId: "",
        SelectfieldId: event.target.value,
        selectedfeildID : event.target.value,
        isValid: true
      })
      this.getCourseListByStreamID(event.target.value)
    }
  }
  onChangeseletectofcourse = (event) => {
    console.log(event.target.value)
    let feildVal = event.target.value;
    if (feildVal === "") {
      this.setState({
        errorTextfieldId: "Enter Email",
        isValid: false
      })
    }
    else {
      this.setState({
        errorTextfieldId: "",
        SelectcourseID: event.target.value,
        isValid: true
      })
      this.getmarketinsigntDetails(event.target.value)
      this.getallemployementIndustries(event.target.value)
      this.getmarketinsigntdetailsbycourseId(event.target.value)
      // this.getAllStateJob(event.target.value)
      // this.getAllCityJob(event.target.value)
    }
  }
  getmarketinsigntdetailsbycourseId = (e) => {
    Api.getallMarketinsightDetailsbycourseId(e).then(
      res => {
        if (res.data.status == "success") {
          console.log(res.data.data[0], "marketinsight")
          this.setState({
            marketinsightdetails: res.data.data[0],
            CityJobs: res.data.data[0].cityJobs,
            districtJobs: res.data.data[0].districtJobs,
            marketSkills: res.data.data[0].marketSkills,
            arrayOfDivs: res.data.data[0].employmentArea,
            arrayOfDivs1: res.data.data[0].designations
          })
        } else {
          console.log("error")
        }
      })
  }
  getmarketinsigntDetails = (e) => {
    Api.getallMarketinsightDetails(e).then(
      res => {
        if (res.data.status == "success") {
          console.log(res.data.data, "resss")
          this.setState({
            marketinsightdetails: res.data.data,
          })
        } else {
          console.log("error")
        }
      })
  }
  getallemployementIndustries = (e) => {
    Api.getallemployementIndustriesDetails(e).then(
      res => {
        if (res.data.status == "success") {
          console.log(res.data.data, "resss")
          this.setState({
            emplyeeindustriesDetails: res.data.data,
            Seletedempindustry: res.data.data[0]
          })
        } else {
          console.log("error")
        }
      })
  }
  seletecempindusty = (event) => {
    console.log(event.target.value)
    let feildname = event.target.value;
    if (feildname === "") {
      this.setState({
        errorTextName: "Enter Email",
        isValid: false
      })
    }
    else {
      this.setState({
        errorTextName: "",
        Seletedempindustry: event.target.value,
        isValid: true
      })
    }
  }
  getCourseListByStreamID = (e) => {
    Api.getallCourseByStreamID(e).then(
      res => {
        if (res.data.status == "success") {
          console.log(res.data.data, "resss")
          this.setState({
            CourseList: res.data.data,
          })
        } else {
          console.log("error")
        }
      })
  }
  getAllStateJob(e) {
    Api.getallstatejobByCourseID(e).then(
      res => {
        if (res.data.status == "success") {
          console.log(res.data.data, "resss")
          this.setState({
            getallstatejobdetails: res.data.data,
          })
        } else {
          console.log("error")
        }
      })
  }
  getAllCityJob(e) {
    Api.getallCityJobByCourseID(e).then(
      res => {
        if (res.data.status == "success") {
          console.log(res.data.data, "resss")
          this.setState({
            getallCityjobdetails: res.data.data,
          })
        } else {
          console.log("error")
        }
      })
  }
  onChangeofnameofcity = (event) => {
    console.log(event.target.value)
    let feildname = event.target.value;
    if (feildname === "") {
      this.setState({
        errorTextName: "Enter Email",
        isValid: false
      })
    }
    else {
      this.setState({
        errorTextName: "",
        newName: event.target.value,
        isValid: true
      })
    }
  }
  onChangeofnameofcityvalue = (event) => {
    console.log(event.target.value)
    let feildVal = event.target.value;
    if (feildVal === "") {
      this.setState({
        errorTextvalue: "Enter Email",
        isValid: false
      })
    }
    else {
      this.setState({
        errorTextvalue: "",
        newValue: event.target.value,
        isValid: true
      })
    }
  }
  Submitcitydetails = () => {
    console.log(this.state.marketinsightdetails)
    let data = {
      "name": this.state.editabledetailsforcitywise.name,
      "newName": this.state.editabledetailsforcitywise.newName ? this.state.editabledetailsforcitywise.newName : this.state.newName,
      "newValue": this.state.editabledetailsforcitywise.newValue ? this.state.editabledetailsforcitywise.newValue : this.state.newValue
    }
    Api.updatedcitydetails(data, this.state.marketinsightdetails._id).then(
      res => {
        console.log(res.data, "res")
        if (res.data.status == "success") {
          toast.success('City Details Update Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          this.onCloseModal()
          // this.getmarketinsigntdetailsbycourseId()
          window.location.reload();
          this.getJobsFeildList()
        } else {
          console.log("error",);
          toast.error('Something Went Wrong', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      }

    ).then(error => {
      console.log(error, "error")
    })
      .catch(error => {
        console.log(error, "error")
      });
    console.log(data)
  }
  Submitdistrictdetails = () => {
    console.log(this.state.marketinsightdetails)
    let data = {
      "name": this.state.editabledetailsfordistrictwise.name,
      "newName": this.state.editabledetailsfordistrictwise.newName ? this.state.editabledetailsfordistrictwise.newName : this.state.newName,
      "newValue": this.state.editabledetailsfordistrictwise.newValue ? this.state.editabledetailsfordistrictwise.newValue : this.state.newValue
    }
    Api.updateddistrictdetails(data, this.state.marketinsightdetails._id).then(
      res => {
        console.log(res.data, "res")
        if (res.data.status == "success") {
          toast.success('district Details Update Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          this.onCloseModal()
          window.location.reload();
          this.getJobsFeildList()
        } else {
          console.log("error",);
          toast.error('Something Went Wrong', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      }

    ).then(error => {
      console.log(error, "error")
    })
      .catch(error => {
        console.log(error, "error")
      });
    console.log(data)
  }
  submit = (e) => {
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure want to Delete',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deletecourseDetails(e)
        },
        {
          label: 'No',
          onClick: () => alert('Click No')
        }
      ]
    });
  };

  deletecourseDetails = (id,) => {
    console.log(id)
    let data = {
      "name": id.name,
      "value": id.value,

    }
    Api.deletecoursedetails(data, this.state.marketinsightdetails._id).then(
      res => {
        console.log(res.data, "res")
        if (res.data.status == "success") {
          toast.success('Stream Details Deleted Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          this.onCloseModal()
          // window.location.reload();
        } else {
          console.log("error",);
          toast.error('Something Went Wrong', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      }

    ).then(error => {
      console.log(error, "error")
    })
      .catch(error => {
        console.log(error, "error")
      });
  }
  districtsubmit = (e) => {
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure want to Delete',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deletedistrctdetails(e)
        },
        {
          label: 'No',
          onClick: () => alert('Click No')
        }
      ]
    });
  };
  deletedistrctdetails = (id,) => {
    console.log(id)
    let data = {
      "name": id.name,
      "value": id.value,

    }
    Api.deletedistrictdetails(data, this.state.marketinsightdetails._id).then(
      res => {
        console.log(res.data, "res")
        if (res.data.status == "success") {
          toast.success('Stream Details Deleted Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          this.onCloseModal()
          window.location.reload();
        } else {
          console.log("error",);
          toast.error('Something Went Wrong', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      }

    ).then(error => {
      console.log(error, "error")
    })
      .catch(error => {
        console.log(error, "error")
      });
  }
  skillssubmit = (e) => {
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure want to Delete',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteskillsdetailsdetails(e)
        },
        {
          label: 'No',
          onClick: () => alert('Click No')
        }
      ]
    });
  }
  deleteskillsdetailsdetails = (id,) => {
    console.log(id)
    let data = {
      "name": id.name,
      "value": id.value,

    }
    Api.deleteskillsdetails(data, this.state.marketinsightdetails._id).then(
      res => {
        console.log(res.data, "res")
        if (res.data.status == "success") {
          toast.success('Stream Details Deleted Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          // this.onCloseModal()
          // window.location.reload();
        } else {
          console.log("error",);
          toast.error('Something Went Wrong', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      }

    ).then(error => {
      console.log(error, "error")
    })
      .catch(error => {
        console.log(error, "error")
      });
  }
  SubmitSkillsdetails = () => {
    console.log(this.state.marketinsightdetails)
    let data = {
      "name": this.state.editabledetailsforSkillswise.name,
      "newName": this.state.editabledetailsforSkillswise.newName ? this.state.editabledetailsforSkillswise.newName : this.state.newName,
      "newValue": this.state.editabledetailsforSkillswise.newValue ? this.state.editabledetailsforSkillswise.newValue : this.state.newValue
    }
    Api.updatedskillsdetails(data, this.state.marketinsightdetails._id).then(
      res => {
        console.log(res.data, "res")
        if (res.data.status == "success") {
          toast.success('district Details Update Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          this.onCloseModal()
          window.location.reload();
          this.getJobsFeildList()
        } else {
          console.log("error",);
          toast.error('Something Went Wrong', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      }

    ).then(error => {
      console.log(error, "error")
    })
      .catch(error => {
        console.log(error, "error")
      });
    console.log(data)
  }
  onChangeofaddnewcityname = (event) => {
    console.log(event.target.value)
    let feildname = event.target.value;
    if (feildname === "") {
      this.setState({
        errorTextName: "Enter Email",
        isValid: false
      })
    }
    else {
      this.setState({
        errorTextName: "",
        name: event.target.value,
        isValid: true
      })
    }
  }
  onChangeofaddcityvalue = (event) => {
    console.log(event.target.value)
    let feildVal = event.target.value;
    if (feildVal === "") {
      this.setState({
        errorTextvalue: "Enter Email",
        isValid: false
      })
    }
    else {
      this.setState({
        errorTextvalue: "",
        value: event.target.value,
        isValid: true
      })
    }
  }
  addnewcitydetails = () => {
    console.log(this.state.marketinsightdetails)
    let data = {
      "name": this.state.name,
      "value": this.state.value,
    }
    Api.addnewcitydetail(data, this.state.marketinsightdetails._id).then(
      res => {
        console.log(res.data, "res")
        if (res.data.status == "success") {
          toast.success('City Details Update Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          this.onCloseModal()
          // window.location.reload();
          this.getJobsFeildList()
        } else {
          console.log("error",);
          toast.error('Something Went Wrong', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      }

    ).then(error => {
      console.log(error, "error")
    })
      .catch(error => {
        console.log(error, "error")
      });
    console.log(data)
  }
  addnewskillsdetails = () => {
    console.log(this.state.marketinsightdetails)
    let data = {
      "name": this.state.name,
      "value": this.state.value,
    }
    Api.addnewSkillsdetail(data, this.state.marketinsightdetails._id).then(
      res => {
        console.log(res.data, "res")
        if (res.data.status == "success") {
          toast.success('City Details Update Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          this.onCloseModal()
          // window.location.reload();
          this.getJobsFeildList()
        } else {
          console.log("error",);
          toast.error('Something Went Wrong', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      }

    ).then(error => {
      console.log(error, "error")
    })
      .catch(error => {
        console.log(error, "error")
      });
    console.log(data)

  }
  addnewDistrictdetails = () => {
    console.log(this.state.marketinsightdetails)
    let data = {
      "name": this.state.name,
      "value": this.state.value,
    }
    Api.addnewDistrictdetail(data, this.state.marketinsightdetails._id).then(
      res => {
        console.log(res.data, "res")
        if (res.data.status == "success") {
          toast.success('City Details Update Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          this.onCloseModal()
          // window.location.reload();
          this.getJobsFeildList()
        } else {
          console.log("error",);
          toast.error('Something Went Wrong', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      }

    ).then(error => {
      console.log(error, "error")
    })
      .catch(error => {
        console.log(error, "error")
      });
    console.log(data)

  }
  onChangeofororglevel = (e) => {
    console.log(e)
  }
  employmentIndustry = (event) => {
    console.log(event.target.value)
    let feildVal = event.target.value;
    if (feildVal === "") {
      this.setState({
        errorTextvalue: "Enter Email",
        isValid: false
      })
    }
    else {
      this.setState({
        errorTextvalue: "",
        Seletedempindustry: event.target.value,
        isValid: true
      })
    }
  }
  addnewemploymentsdetails = () => {
    console.log(this.state.Seletedempindustry)
    let data = {
      "name": this.state.name,
    }
    let id = this.state.Seletedempindustry._id ? this.state.Seletedempindustry._id : this.state.Seletedempindustry
    Api.addnewemployeedetail(data, id).then(
      res => {
        console.log(res.data, "res")
        if (res.data.status == "success") {
          toast.success('City Details Update Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          this.onCloseModal()
          window.location.reload();
          this.getJobsFeildList()
        } else {
          console.log("error",);
          toast.error('Something Went Wrong', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      }

    ).then(error => {
      console.log(error, "error")
    })
      .catch(error => {
        console.log(error, "error")
      });
    console.log(data)

  }
  editnewemploymentsareadetails = () => {
    let data = {
      "name": this.state.areaupdatesdetails,
      "newName": this.state.name
    }
    let id = this.state.Seletedempindustry._id ? this.state.Seletedempindustry._id : this.state.Seletedempindustry
    Api.editnewemployeeareadetail(data, id).then(
      res => {
        console.log(res.data, "res")
        if (res.data.status == "success") {
          toast.success('City Details Update Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          // this.onCloseModal()
          window.location.reload();
          // this.getJobsFeildList()
        } else {
          console.log("error",);
          toast.error('Something Went Wrong', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      }

    ).then(error => {
      console.log(error, "error")
    })
      .catch(error => {
        console.log(error, "error")
      });
    console.log(data)
  }
  areasubmit = (e) => {
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure want to Delete',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deletenewemploymentsareadetails(e)
        },
        {
          label: 'No',
          onClick: () => alert('Click No')
        }
      ]
    });
  }
  deletenewemploymentsareadetails = (e) => {
    console.log(e, this.state.Seletedempindustry)
    let data = {
      "name": e,
    }
    let id = this.state.Seletedempindustry._id ? this.state.Seletedempindustry._id : this.state.Seletedempindustry
    Api.removenewemployeeareadetail(data, id).then(
      res => {
        console.log(res.data, "res")
        if (res.data.status == "success") {
          toast.success('City Details Update Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          // this.onCloseModal()
          // window.location.reload();
          // this.getJobsFeildList()
        } else {
          console.log("error",);
          toast.error('Something Went Wrong', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      }

    ).then(error => {
      console.log(error, "error")
    })
      .catch(error => {
        console.log(error, "error")
      });
    console.log(data)

  }

  addnewdesigntiondetails = () => {
    console.log(this.state.Seletedempindustry)
    let data = {
      "name": this.state.name,
    }
    let id = this.state.Seletedempindustry._id ? this.state.Seletedempindustry._id : this.state.Seletedempindustry
    Api.addnewdesigntiondetail(data, id).then(
      res => {
        console.log(res.data, "res")
        if (res.data.status == "success") {
          toast.success('City Details Update Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          // this.onCloseModal()
          // window.location.reload();
          // this.getJobsFeildList()
        } else {
          console.log("error",);
          toast.error('Something Went Wrong', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      }

    ).then(error => {
      console.log(error, "error")
    })
      .catch(error => {
        console.log(error, "error")
      });
    console.log(data)

  }
  editnewdesigntiondetails = () => {
    let data = {
      "name": this.state.designtionupdatesdetails,
      "newName": this.state.name
    }
    let id = this.state.Seletedempindustry._id ? this.state.Seletedempindustry._id : this.state.Seletedempindustry
    Api.editnewdesigntiondetail(data, id).then(
      res => {
        console.log(res.data, "res")
        if (res.data.status == "success") {
          toast.success('City Details Update Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          // this.onCloseModal()
          // window.location.reload();
          // this.getJobsFeildList()
        } else {
          console.log("error",);
          toast.error('Something Went Wrong', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      }

    ).then(error => {
      console.log(error, "error")
    })
      .catch(error => {
        console.log(error, "error")
      });
    console.log(data)
  }
  deletesubmit = (e) => {
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure want to Delete',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deletenewdesigntiondetails(e)
        },
        {
          label: 'No',
          onClick: () => alert('Click No')
        }
      ]
    });
  }
  deletenewdesigntiondetails = (e) => {
    console.log(e, this.state.Seletedempindustry)
    let data = {
      "name": e,
    }
    let id = this.state.Seletedempindustry._id ? this.state.Seletedempindustry._id : this.state.Seletedempindustry
    console.log(id)
    Api.removenewdesigntiondetail(data, id).then(
      res => {
        console.log(res.data, "res")
        if (res.data.status == "success") {
          toast.success('City Details Update Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          // this.onCloseModal()
          // window.location.reload();
          // this.getJobsFeildList()
        } else {
          console.log("error",);
          toast.error('Something Went Wrong', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      }

    ).then(error => {
      console.log(error, "error")
    })
      .catch(error => {
        console.log(error, "error")
      });
    console.log(data)

  }
  addnewskillsdetails = () => {
    console.log(this.state.marketinsightdetails)
    let data = {
      "name": this.state.name,
      "value": this.state.value,
    }
    Api.addnewSkillsdetail(data, this.state.marketinsightdetails._id).then(
      res => {
        console.log(res.data, "res")
        if (res.data.status == "success") {
          toast.success('City Details Update Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          this.onCloseModal()
          // window.location.reload();
          this.getJobsFeildList()
        } else {
          console.log("error",);
          toast.error('Something Went Wrong', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      }

    ).then(error => {
      console.log(error, "error")
    })
      .catch(error => {
        console.log(error, "error")
      });
    console.log(data)

  }
  onchnageupdatejobindia = (event) => {
    console.log(event.target.value)
    let feildVal = event.target.value;
    if (feildVal === "") {
      this.setState({
        errorTextvalue: "Enter Email",
        isValid: false
      })
    }
    else {
      this.setState({
        errorTextvalue: "",
        jobsInIndia: event.target.value,
        isValid: true
      })
    }
  }
  updatesjobsinindia = () => {
    console.log(this.state.marketinsightdetails)
    let data = {
      "jobsInIndia": this.state.jobsInIndia,
    }
    Api.updatejobsInIndiadetail(data, this.state.marketinsightdetails._id).then(
      res => {
        console.log(res.data, "res")
        if (res.data.status == "success") {
          toast.success('City Details Update Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          this.onCloseModal()
          // window.location.reload();
          this.getJobsFeildList()
        } else {
          console.log("error",);
          toast.error('Something Went Wrong', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      }

    ).then(error => {
      console.log(error, "error")
    })
      .catch(error => {
        console.log(error, "error")
      });
    console.log(data)

  }
  render() {
    return (
      <>
        <Row className="navba">
          <Col lg={12}>


            <Nav fill defaultActiveKey={"feild.name"} variant="pills" className="flex-column flex-sm-row">
              {this.state.JobFeilds?.map((feild, index) => (<>
                <Nav.Item>
                  <Nav.Link eventKey={feild.name} href="#" onClick={() => this.feilddetails(feild)} className="mb-sm-3 mb-md-0">
                    {feild.name}
                  </Nav.Link>
                </Nav.Item>
              </>))}
              {/* <Nav.Item>
                <Nav.Link eventKey="profile" href="#" className="mb-sm-3 mb-md-0">
                  Degree Students
            </Nav.Link>
              </Nav.Item> */}
              <Nav.Item>
                <Nav.Link eventKey="settings" href="#/components/job-listing/" className="mb-sm-3 mb-md-0">
                  Job Available
            </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="messages" href="#" className="mb-sm-3 mb-md-0">
                  Candidates
            </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="List" href="#" className="mb-sm-3 mb-md-0">
                  College List
            </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
        <Row className="justify-content-md-center mt-4 ff">
          <Col lg={10}>
            <Col className="text-start">
              <React.Fragment>
                <div className="d-xl-flex align-items-center">
                  <div className="file-field" style={{ width: "100%" }}>
                    <div className="d-flex justify-content-xl-end ms-xl-3">
                      <Button onClick={() =>this.onClickButton11()} variant="success" className="m-1 d-flex">
                        {/* <input type="file" accept=".xlsx, .xls, .csv" />
                        <div className="d-md-block text-center"> */}
                          Bulk Upload
                        {/* </div> */}
                      </Button>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            </Col>
            <Form>
              <Form.Group className="mb-3" style={{ display: "flex" }}>
                <Form.Label>Select Stream</Form.Label>
                <Form.Select onChange={this.onChangeseletectOffeild}>
                  <option value="1">Selecte Stream List </option>
                  {this.state.StreamByFeildsId?.map((feild, index) => (<>   <option value={feild._id}>{feild.name}</option> </>))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" style={{ display: "flex" }}>
                <Form.Label>Select Courses</Form.Label>
                <Form.Select onChange={this.onChangeseletectofcourse}>
                  <option value="1">Selecte Courses List </option>
                  {this.state.CourseList?.map((feild, index) => (<>   <option value={feild._id}>{feild.name}</option> </>))}
                </Form.Select>
              </Form.Group>
              {/* <Row >
            <Col lg={11} className="text-end"> */}
              <Form.Group className="mb-3" style={{ display: "flex" }}>
                <Form.Label>Course Discription</Form.Label>
                <Form.Control as="textarea" defaultValue={this.state.marketinsightdetails.description} readOnly={this.state.readOnly} rows="4" placeholder="Enter Course Discription..." />
                <Col>
                  <Button variant="secondary" onClick={() => this._click()} className="btnss" style={{ height: "115px" }} size="sm"><FontAwesomeIcon icon={faPencilAlt} className="" /></Button>
                </Col>
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row >
          <Col lg={12}>
            <Card border="light" className="shadow-sm">
              <Card.Header>
                <Row className="align-items-center">
                  <Col lg={5}>
                    <h5>Employment Industry</h5>
                  </Col>
                  <Col lg={6} className="text-end" style={{ marginBottom: "-25px" }}>
                    <Form>
                      <Form.Group className="mb-3">
                        <Form.Select onChange={this.seletecempindusty}>
                          <option value="1">Selecte Courses List </option>
                          {this.state.emplyeeindustriesDetails?.map((feild, index) => (<>   <option value={feild._id}>{feild.employmentIndustry}</option> </>))}

                        </Form.Select>

                      </Form.Group>

                    </Form>
                  </Col>
                  <Col lg={1} className="text-end">
                    <Button variant="secondary" onClick={() => this.onClickButton()} className="btns" size="sm"><FontAwesomeIcon icon={faPlusCircle} className="" /></Button>
                  </Col>
                </Row>
              </Card.Header>
              <Row>
                <Col lg={6}>
                  <div className="row" style={{ padding: "0px 10px" }}>
                    <Col lg={10}>
                      <h5 style={{ fontSize: "18px", paddingTop: "3px" }}><b>Employment Area</b> </h5>
                    </Col>
                    <Col lg={2} className="text-end">
                      <Button variant="secondary text-end" style={{ padding: '4px' }} onClick={() => this.onClickButton()} className="btns" size="sm"><FontAwesomeIcon icon={faPlusCircle} className="" /></Button>
                    </Col>
                    {this.state.arrayOfDivs.map((item) => (<>
                      <Col lg={10}>
                        <div className=" form-group" key={item.id}>
                          <Form.Group className="mb-3">
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              defaultValue={item}
                              placeholder="Enter Employment Area"
                              onChange={(e) => this.onChangeofororglevel(item)}
                            />
                          </Form.Group>
                        </div>
                      </Col>
                      <Col lg={2}>
                        <div className="mt-2">
                          <FontAwesomeIcon onClick={() => this.onClickButtonarea(item)} icon={faEdit} cursor="pointer" className="me-2" /> <FontAwesomeIcon onClick={() => this.areasubmit(item)} icon={faTrashAlt} className="me-2" />
                        </div>
                      </Col>
                    </>))}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="row" style={{ padding: "0px 10px" }}>
                    <Col lg={8}>
                      <h5 style={{ fontSize: "18px", paddingTop: "3px" }}><b>Designation</b> </h5>
                    </Col>
                    <Col lg={4} className="text-end">
                      <Button variant="secondary text-end" style={{ padding: '4px' }} onClick={this.onClickButton8} className="btns" size="sm"><FontAwesomeIcon icon={faPlusCircle} className="" /></Button>
                    </Col>
                    {this.state.arrayOfDivs1.map((item) => (<>
                      <Col lg={10}>
                        <div className="col-md-12 form-group" key={item.id}>
                          <Form.Group className="mb-3">
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              defaultValue={item}
                              placeholder="Enter Designation"
                              onChange={(e) => this.onChangeofororglevel(item)}
                            />
                          </Form.Group>
                        </div>
                      </Col>
                      <Col lg={2}>
                        <div className="mt-2">
                          <FontAwesomeIcon onClick={() => this.onClickButtondesigntion(item)} icon={faEdit} cursor="pointer" className="me-2" /> <FontAwesomeIcon onClick={() => this.deletesubmit(item)} icon={faTrashAlt} className="me-2" />
                        </div>
                      </Col>
                    </>))}
                  </div>
                </Col>
              </Row>

            </Card>
          </Col>

        </Row>
        <Row className="mt-4">
          <Col lg={6}>
            <Card border="light" className="shadow-sm" style={{ height: "350px" }}>
              <Card.Header>
                <Row className="align-items-center">
                  <Col>
                    <h5>Skills</h5>
                  </Col>
                  <Col className="text-end">
                    <Button variant="secondary" onClick={() => this.onClickButton1()} size="sm"><FontAwesomeIcon icon={faPlusCircle} className="me-2" /> Add New</Button>
                  </Col>
                </Row>
              </Card.Header>
              <Table responsive className="align-items-center table-flush">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Skills Name</th>
                    <th scope="col">Demand %</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>

                  {this.state.marketSkills.map((skills, index) => (<>
                    <tr>
                      <td scope="col">{skills.name}</td>
                      <td scope="col">{skills.value}</td>
                      <td scope="col"> <FontAwesomeIcon icon={faEdit} onClick={() => this.onClickButton2(skills)} cursor="pointer" className="me-2" /> <FontAwesomeIcon onClick={() => this.skillssubmit(skills)} icon={faTrashAlt} className="me-2" /></td>
                    </tr>
                  </>))}
                </tbody>
              </Table>
            </Card>
          </Col>
          <Col lg={6}>
            <Card border="light" className="shadow-sm" style={{ height: "350px" }}>
              <Card.Header>
                <Row className="align-items-center">
                  <Col>
                    <h5>Job Demond</h5>
                  </Col>
                  <Col className="text-end">
                    {/* <Button variant="secondary" onClick={() => this.onClickButton3()} size="sm"><FontAwesomeIcon icon={faPlusCircle} className="me-2" /> Add New</Button> */}
                  </Col>
                </Row>
              </Card.Header>
              <Table responsive className="align-items-center table-flush">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Country </th>
                    <th scope="col">Demond No</th>
                    <th scope="col">Top Employees</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td scope="col">India</td>
                    <td scope="col">{this.state.marketinsightdetails.jobsInIndia}</td>
                    <td scope="col">info</td>
                    <td scope="col"><FontAwesomeIcon icon={faEdit} onClick={() => this.onClickButton10(this.state.marketinsightdetails.jobsInIndia)} cursor="pointer" className="me-2" /> </td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </Col>


        </Row>
        <Row className="mt-4">
          <Col lg={6}>
            <Card border="light" className="shadow-sm" style={{ height: "350px" }}>
              <Card.Header>
                <Row className="align-items-center">
                  <Col>
                    <h5>City Wise</h5>
                  </Col>
                  <Col className="text-end">
                    <Button variant="secondary" onClick={() => this.onClickButton3()} size="sm"><FontAwesomeIcon icon={faPlusCircle} className="me-2" /> Add New</Button>
                  </Col>
                </Row>
              </Card.Header>
              <Table responsive className="align-items-center table-flush">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">State</th>
                    <th scope="col">No</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.CityJobs.map((state, index) => (<>
                    <tr>
                      <td scope="col">{state.name}</td>
                      <td scope="col">{state.value}</td>
                      <td scope="col"> <FontAwesomeIcon icon={faEdit} onClick={() => this.onClickButton4(state)} cursor="pointer" className="me-2" /> <FontAwesomeIcon onClick={() => this.submit(state)} icon={faTrashAlt} className="me-2" /></td>
                    </tr>
                  </>))}
                </tbody>
              </Table>
            </Card>
          </Col>
          <Col lg={6}>
            <Card border="light" className="shadow-sm" style={{ height: "350px" }}>
              <Card.Header>
                <Row className="align-items-center">
                  <Col>
                    <h5>District</h5>
                  </Col>
                  <Col className="text-end">
                    <Button variant="secondary" onClick={() => this.onClickButton6()} size="sm"><FontAwesomeIcon icon={faPlusCircle} className="me-2" /> Add New1</Button>
                  </Col>
                </Row>
              </Card.Header>
              <Table responsive className="align-items-center table-flush">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">District</th>
                    <th scope="col">No</th>
                    <th scope="col">Top Employees</th>
                    <th scope="col" >Action </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.districtJobs.map((City, index) => (<>
                    <tr>
                      <td scope="col">{City.name}</td>
                      <td scope="col">{City.value}</td>
                      <td scope="col">info</td>
                      <td scope="col"> <FontAwesomeIcon onClick={() => this.onClickButton5(City)} cursor="pointer" icon={faEdit} className="me-2" /> <FontAwesomeIcon onClick={() => this.districtsubmit(City)} icon={faTrashAlt} className="me-2" /></td>
                    </tr>
                  </>))}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>

        <div>
          <Modal open={this.state.openModal} onClose={this.onCloseModal}>
            <h4 className="shareprofile text-center">Add Employment Details</h4>

            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Add Employment Area </Form.Label>
                <Form.Control type="text" onChange={this.onChangeofaddnewcityname} placeholder="Enter Course" />
              </Form.Group>
              <Col className="text-center">
                <Button variant="secondary text-center" onClick={this.addnewemploymentsdetails} size="md"> Submit</Button>
              </Col>
            </Form>
          </Modal>
        </div>
        <div>
          <Modal open={this.state.openModal8} onClose={this.onCloseModal8}>
            <h4 className="shareprofile text-center">Add Designation </h4>

            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Add Designation  </Form.Label>
                <Form.Control type="text" onChange={this.onChangeofaddnewcityname} placeholder="Enter Course" />
              </Form.Group>
              <Col className="text-center">
                <Button variant="secondary text-center" onClick={this.addnewdesigntiondetails} size="md"> Submit</Button>
              </Col>
            </Form>
          </Modal>
        </div>
        <div>
          <Modal open={this.state.openModal7} onClose={this.onCloseModal7}>
            <h4 className="shareprofile text-center">Edit Employment Details</h4>

            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Add Employment Area </Form.Label>
                <Form.Control type="text" onChange={this.onChangeofaddnewcityname} defaultValue={this.state.areaupdatesdetails ? this.state.areaupdatesdetails : this.state.name} placeholder="Enter Course" />
              </Form.Group>
              <Col className="text-center">
                <Button variant="secondary text-center" onClick={this.editnewemploymentsareadetails} size="md"> Submit</Button>
              </Col>
            </Form>
          </Modal>
        </div>
        <div>
          <Modal open={this.state.openModal9} onClose={this.onCloseModal9}>
            <h4 className="shareprofile text-center">Edit Designtion Details</h4>

            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Designtion  </Form.Label>
                <Form.Control type="text" onChange={this.onChangeofaddnewcityname} defaultValue={this.state.designtionupdatesdetails ? this.state.designtionupdatesdetails : this.state.name} placeholder="Enter Course" />
              </Form.Group>
              <Col className="text-center">
                <Button variant="secondary text-center" onClick={this.editnewdesigntiondetails} size="md"> Submit</Button>
              </Col>
            </Form>
          </Modal>
        </div>
        <div>
          <Modal open={this.state.openModal10} onClose={this.onCloseModal10}>
            <h4 className="shareprofile text-center">Edit Jobs In India</h4>

            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Job in india  </Form.Label>
                <Form.Control type="text" onChange={this.onchnageupdatejobindia} defaultValue={this.state.updatedjobsindia ? this.state.updatedjobsindia : this.state.jobsInIndia} placeholder="Enter Course" />
              </Form.Group>
              <Col className="text-center">
                <Button variant="secondary text-center" onClick={this.updatesjobsinindia} size="md"> Submit</Button>
              </Col>
            </Form>
          </Modal>
        </div>
        <div>
          <Modal open={this.state.openModal1} onClose={this.onCloseModal1}>
            <h4 className="shareprofile text-center">Add Skills Details</h4>

            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Skill Name  </Form.Label>
                <Form.Control onChange={this.onChangeofaddnewcityname} type="text" placeholder="Enter City Name" />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Value </Form.Label>
                <Form.Control type="text" onChange={this.onChangeofaddcityvalue} placeholder="Enter Demand No" />
              </Form.Group>
              <Col className="text-center">
                <Button variant="secondary text-center" onClick={this.addnewskillsdetails} size="md"> Submit</Button>
              </Col>
            </Form>
          </Modal>
        </div>

        <div>
          <Modal open={this.state.openModal2} onClose={this.onCloseModal2}>
            <h4 className="shareprofile text-center">Add Skills Details</h4>

            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Skills Name  </Form.Label>
                <Form.Control onChange={this.onChangeofnameofcity} type="text" defaultValue={this.state.editabledetailsforSkillswise.name ? this.state.editabledetailsforSkillswise.name : this.state.name} placeholder="Enter Skills Name" />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Demand </Form.Label>
                <Form.Control type="text" onChange={this.onChangeofnameofcityvalue} defaultValue={this.state.editabledetailsforSkillswise.value ? this.state.editabledetailsforSkillswise.value : this.state.value} placeholder="Enter Demand No" />
              </Form.Group>
              <Col className="text-center">
                <Button variant="secondary text-center" onClick={this.SubmitSkillsdetails} size="md"> Submit</Button>
              </Col>
            </Form>
          </Modal>
        </div>

        <div>
          <Modal open={this.state.openModal3} onClose={this.onCloseModal3}>
            <h4 className="shareprofile text-center">Add City wise job</h4>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>City Name  </Form.Label>
                <Form.Control onChange={this.onChangeofaddnewcityname} type="text" placeholder="Enter City Name" />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Value </Form.Label>
                <Form.Control type="text" onChange={this.onChangeofaddcityvalue} placeholder="Enter Demand No" />
              </Form.Group>
              <Col className="text-center">
                <Button variant="secondary text-center" onClick={this.addnewcitydetails} size="md"> Submit</Button>
              </Col>
            </Form>
          </Modal>
        </div>

        <div>
          <Modal open={this.state.openModal4} onClose={this.onCloseModal4}>
            <h4 className="shareprofile text-center">Job Demond</h4>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>City Name  </Form.Label>
                <Form.Control onChange={this.onChangeofnameofcity} type="text" defaultValue={this.state.editabledetailsforcitywise.name ? this.state.editabledetailsforcitywise.name : this.state.name} placeholder="Enter City Name" />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Value </Form.Label>
                <Form.Control type="text" onChange={this.onChangeofnameofcityvalue} defaultValue={this.state.editabledetailsforcitywise.value ? this.state.editabledetailsforcitywise.value : this.state.value} placeholder="Enter Demand No" />
              </Form.Group>
              <Col className="text-center">
                <Button variant="secondary text-center" onClick={this.Submitcitydetails} size="md"> Submit</Button>
              </Col>
            </Form>
          </Modal>
        </div>


        <div>
          <Modal open={this.state.openModal5} onClose={this.onCloseModal5}>
            <h4 className="shareprofile text-center">Job Demond</h4>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>District Name  </Form.Label>
                <Form.Control onChange={this.onChangeofnameofcity} type="text" defaultValue={this.state.editabledetailsfordistrictwise.name ? this.state.editabledetailsfordistrictwise.name : this.state.name} placeholder="Enter City Name" />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Value </Form.Label>
                <Form.Control type="text" onChange={this.onChangeofnameofcityvalue} defaultValue={this.state.editabledetailsfordistrictwise.value ? this.state.editabledetailsfordistrictwise.value : this.state.value} placeholder="Enter Demand No" />
              </Form.Group>
              <Col className="text-center">
                <Button variant="secondary text-center" onClick={this.Submitdistrictdetails} size="md"> Submit</Button>
              </Col>
            </Form>
          </Modal>
          <ToastContainer />
        </div>

        <div>
          <Modal open={this.state.openModal6} onClose={this.onCloseModal6}>
            <h4 className="shareprofile text-center">Add District wise job</h4>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>City Name  </Form.Label>
                <Form.Control onChange={this.onChangeofaddnewcityname} type="text" placeholder="Enter City Name" />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Value </Form.Label>
                <Form.Control type="text" onChange={this.onChangeofaddcityvalue} placeholder="Enter Demand No" />
              </Form.Group>
              <Col className="text-center">
                <Button variant="secondary text-center" onClick={this.addnewDistrictdetails} size="md"> Submit</Button>
              </Col>
            </Form>
          </Modal>
        </div>
        <div>
          <Modal open={this.state.openModal11} onClose={this.onCloseModal11}>
            <h4 className="shareprofile text-center">Bulk Upload Course</h4>
            <Form>
              <Form.Group >
                <Form.Label>Select Feild </Form.Label>
                <Form.Select defaultValue="0" onChange={this.onChangeseletectOffeild}>
                  <option value="1">Selecte Feild List </option>
                  {this.state.JobFeilds?.map((feild, index) => (<>   <option value={feild._id}>{feild.name}</option> </>))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Bulk Upload</Form.Label>
                <Form.Control type="file" accept=".xlsx, .xls, .csv" onChange={this.onClickofbulkupload}></Form.Control>
              </Form.Group>
              <Col className="text-center">
                <Button variant="secondary text-center" onClick={this.onClickexcelupload} size="md"> Submit</Button>
              </Col>
            </Form>
          </Modal>
          <ToastContainer />
        </div>
      </>
    );
  };
};
export default dashboard;