
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faChartLine, faCloudUploadAlt, faEdit,faTimes, faTrashAlt,faCheck, faPlus, faRocket, faTasks,faDownload, faUserShield, faPlusCircle,faEye } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Span, Dropdown, ButtonGroup, Card, Nav, Table, Form, FormLabel } from '@themesberg/react-bootstrap';
import { PageVisitsTable } from "../../components/Tables";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Api from '../../service/api';
import { Link } from 'react-router-dom';
class dashboard extends React.Component {
    constructor() {
        super();
        this.state = {
            readOnly: true,
            arrayOfDivs: [],
            divID: 1,
            arrayOfDivs1: [],
            divID1: 1,
            CourseList: [],
            JobFeilds: [],
            errorTextName: '',
            name: '',
            streamId: '',
            errorTextstreamId: '',
        }
    }
    componentDidMount() {
    }

    render() {
        return (
            <>
                <Card border="light" className="shadow-sm">
                    <Card.Header>
                        <Row className="align-items-center">
                            <Col>
                                <h5>Jobs Application</h5>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Table responsive className="align-items-center table-flush">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">No</th>
                                <th scope="col">Position</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Phone Number</th>
                                <th scope="col">Applied On </th>
                                <th scope="col">Submitted By </th>
                                <th scope="col">Status</th>
                                <th scope="col">Active</th>
                            </tr>
                        </thead>
                        <tbody>
                        
                                <tr>
                                    <td>1.</td>
                                    <td>Database analyst</td>
                                    <td>Jordan</td>
                                    <td>Jordan@gmail.com</td>
                                    <td>1234598765</td>
                                    <td>25-01-2021</td>
                                    <td>Nicholas</td>
                                    <td><Button variant="success" className="smbtn">Active</Button></td>
                                    <td><FontAwesomeIcon className="red" icon={faTimes} /><FontAwesomeIcon className="green" icon={faCheck} /><Link to="/components/candidate-profile"> <FontAwesomeIcon className="brown" icon={faEye} /></Link><FontAwesomeIcon className="red" icon={faDownload} /><FontAwesomeIcon className="ornge" icon={faTrashAlt} /></td>
                                </tr>

                                <tr>
                                    <td>1.</td>
                                    <td>Database analyst</td>
                                    <td>Jordan</td>
                                    <td>Jordan@gmail.com</td>
                                    <td>1234598765</td>
                                    <td>25-01-2021</td>
                                    <td>Nicholas</td>
                                    <td><Button variant="success" className="smbtn">Active</Button></td>
                                    <td><FontAwesomeIcon className="red" icon={faTimes} /><FontAwesomeIcon className="green" icon={faCheck} /><Link to="/components/candidate-profile"> <FontAwesomeIcon className="brown" icon={faEye} /></Link><FontAwesomeIcon className="red" icon={faDownload} /><FontAwesomeIcon className="ornge" icon={faTrashAlt} /></td>
                                </tr>

                                <tr>
                                    <td>1.</td>
                                    <td>Database analyst</td>
                                    <td>Jordan</td>
                                    <td>Jordan@gmail.com</td>
                                    <td>1234598765</td>
                                    <td>25-01-2021</td>
                                    <td>Nicholas</td>
                                    <td><Button variant="success" className="smbtn">Active</Button></td>
                                    <td><FontAwesomeIcon className="red" icon={faTimes} /><FontAwesomeIcon className="green" icon={faCheck} /><Link to="/components/candidate-profile"> <FontAwesomeIcon className="brown" icon={faEye} /></Link><FontAwesomeIcon className="red" icon={faDownload} /><FontAwesomeIcon className="ornge" icon={faTrashAlt} /></td>
                                </tr>

                                <tr>
                                    <td>1.</td>
                                    <td>Database analyst</td>
                                    <td>Jordan</td>
                                    <td>Jordan@gmail.com</td>
                                    <td>1234598765</td>
                                    <td>25-01-2021</td>
                                    <td>Nicholas</td>
                                    <td><Button variant="success" className="smbtn">Active</Button></td>
                                    <td><FontAwesomeIcon className="red" icon={faTimes} /><FontAwesomeIcon className="green" icon={faCheck} /><Link to="/components/candidate-profile"> <FontAwesomeIcon className="brown" icon={faEye} /></Link><FontAwesomeIcon className="red" icon={faDownload} /><FontAwesomeIcon className="ornge" icon={faTrashAlt} /></td>
                                </tr>

                                <tr>
                                    <td>1.</td>
                                    <td>Database analyst</td>
                                    <td>Jordan</td>
                                    <td>Jordan@gmail.com</td>
                                    <td>1234598765</td>
                                    <td>25-01-2021</td>
                                    <td>Nicholas</td>
                                    <td><Button variant="success" className="smbtn">Active</Button></td>
                                    <td><FontAwesomeIcon className="red" icon={faTimes} /><FontAwesomeIcon className="green" icon={faCheck} /><Link to="/components/candidate-profile"> <FontAwesomeIcon className="brown" icon={faEye} /></Link><FontAwesomeIcon className="red" icon={faDownload} /><FontAwesomeIcon className="ornge" icon={faTrashAlt} /></td>
                                </tr>

                                <tr>
                                    <td>1.</td>
                                    <td>Database analyst</td>
                                    <td>Jordan</td>
                                    <td>Jordan@gmail.com</td>
                                    <td>1234598765</td>
                                    <td>25-01-2021</td>
                                    <td>Nicholas</td>
                                    <td><Button variant="success" className="smbtn">Active</Button></td>
                                    <td><FontAwesomeIcon className="red" icon={faTimes} /><FontAwesomeIcon className="green" icon={faCheck} /><Link to="/components/candidate-profile"> <FontAwesomeIcon className="brown" icon={faEye} /></Link><FontAwesomeIcon className="red" icon={faDownload} /><FontAwesomeIcon className="ornge" icon={faTrashAlt} /></td>
                                </tr>

                                <tr>
                                    <td>1.</td>
                                    <td>Database analyst</td>
                                    <td>Jordan</td>
                                    <td>Jordan@gmail.com</td>
                                    <td>1234598765</td>
                                    <td>25-01-2021</td>
                                    <td>Nicholas</td>
                                    <td><Button variant="success" className="smbtn">Active</Button></td>
                                    <td><FontAwesomeIcon className="red" icon={faTimes} /><FontAwesomeIcon className="green" icon={faCheck} /><Link to="/components/candidate-profile"> <FontAwesomeIcon className="brown" icon={faEye} /></Link><FontAwesomeIcon className="red" icon={faDownload} /><FontAwesomeIcon className="ornge" icon={faTrashAlt} /></td>
                                </tr>
                                <tr>
                                    <td>1.</td>
                                    <td>Database analyst</td>
                                    <td>Jordan</td>
                                    <td>Jordan@gmail.com</td>
                                    <td>1234598765</td>
                                    <td>25-01-2021</td>
                                    <td>Nicholas</td>
                                    <td><Button variant="success" className="smbtn">Active</Button></td>
                                    <td><FontAwesomeIcon className="red" icon={faTimes} /><FontAwesomeIcon className="green" icon={faCheck} /><Link to="/components/candidate-profile"> <FontAwesomeIcon className="brown" icon={faEye} /></Link><FontAwesomeIcon className="red" icon={faDownload} /><FontAwesomeIcon className="ornge" icon={faTrashAlt} /></td>
                                </tr>

                                <tr>
                                    <td>1.</td>
                                    <td>Database analyst</td>
                                    <td>Jordan</td>
                                    <td>Jordan@gmail.com</td>
                                    <td>1234598765</td>
                                    <td>25-01-2021</td>
                                    <td>Nicholas</td>
                                    <td><Button variant="success" className="smbtn">Active</Button></td>
                                    <td><FontAwesomeIcon className="red" icon={faTimes} /><FontAwesomeIcon className="green" icon={faCheck} /><Link to="/components/candidate-profile"> <FontAwesomeIcon className="brown" icon={faEye} /></Link><FontAwesomeIcon className="red" icon={faDownload} /><FontAwesomeIcon className="ornge" icon={faTrashAlt} /></td>
                                </tr>

                                <tr>
                                    <td>1.</td>
                                    <td>Database analyst</td>
                                    <td>Jordan</td>
                                    <td>Jordan@gmail.com</td>
                                    <td>1234598765</td>
                                    <td>25-01-2021</td>
                                    <td>Nicholas</td>
                                    <td><Button variant="success" className="smbtn">Active</Button></td>
                                    <td><FontAwesomeIcon className="red" icon={faTimes} /><FontAwesomeIcon className="green" icon={faCheck} /><Link to="/components/candidate-profile"> <FontAwesomeIcon className="brown" icon={faEye} /></Link><FontAwesomeIcon className="red" icon={faDownload} /><FontAwesomeIcon className="ornge" icon={faTrashAlt} /></td>
                                </tr>

                                <tr>
                                    <td>1.</td>
                                    <td>Database analyst</td>
                                    <td>Jordan</td>
                                    <td>Jordan@gmail.com</td>
                                    <td>1234598765</td>
                                    <td>25-01-2021</td>
                                    <td>Nicholas</td>
                                    <td><Button variant="success" className="smbtn">Active</Button></td>
                                    <td><FontAwesomeIcon className="red" icon={faTimes} /><FontAwesomeIcon className="green" icon={faCheck} /><Link to="/components/candidate-profile"> <FontAwesomeIcon className="brown" icon={faEye} /></Link><FontAwesomeIcon className="red" icon={faDownload} /><FontAwesomeIcon className="ornge" icon={faTrashAlt} /></td>
                                </tr>

                                <tr>
                                    <td>1.</td>
                                    <td>Database analyst</td>
                                    <td>Jordan</td>
                                    <td>Jordan@gmail.com</td>
                                    <td>1234598765</td>
                                    <td>25-01-2021</td>
                                    <td>Nicholas</td>
                                    <td><Button variant="success" className="smbtn">Active</Button></td>
                                    <td><FontAwesomeIcon className="red" icon={faTimes} /><FontAwesomeIcon className="green" icon={faCheck} /><Link to="/components/candidate-profile"> <FontAwesomeIcon className="brown" icon={faEye} /></Link><FontAwesomeIcon className="red" icon={faDownload} /><FontAwesomeIcon className="ornge" icon={faTrashAlt} /></td>
                                </tr>
                                <tr>
                                    <td>1.</td>
                                    <td>Database analyst</td>
                                    <td>Jordan</td>
                                    <td>Jordan@gmail.com</td>
                                    <td>1234598765</td>
                                    <td>25-01-2021</td>
                                    <td>Nicholas</td>
                                    <td><Button variant="success" className="smbtn">Active</Button></td>
                                    <td><FontAwesomeIcon className="red" icon={faTimes} /><FontAwesomeIcon className="green" icon={faCheck} /><Link to="/components/candidate-profile"> <FontAwesomeIcon className="brown" icon={faEye} /></Link><FontAwesomeIcon className="red" icon={faDownload} /><FontAwesomeIcon className="ornge" icon={faTrashAlt} /></td>
                                </tr>

                                <tr>
                                    <td>1.</td>
                                    <td>Database analyst</td>
                                    <td>Jordan</td>
                                    <td>Jordan@gmail.com</td>
                                    <td>1234598765</td>
                                    <td>25-01-2021</td>
                                    <td>Nicholas</td>
                                    <td><Button variant="danger" className="smbtn">In Active</Button></td>
                                    <td><FontAwesomeIcon className="red" icon={faTimes} /><FontAwesomeIcon className="green" icon={faCheck} /><Link to="/components/candidate-profile"> <FontAwesomeIcon className="brown" icon={faEye} /></Link><FontAwesomeIcon className="red" icon={faDownload} /><FontAwesomeIcon className="ornge" icon={faTrashAlt} /></td>
                                </tr>

                                <tr>
                                    <td>1.</td>
                                    <td>Database analyst</td>
                                    <td>Jordan</td>
                                    <td>Jordan@gmail.com</td>
                                    <td>1234598765</td>
                                    <td>25-01-2021</td>
                                    <td>Nicholas</td>
                                    <td><Button variant="success" className="smbtn">Active</Button></td>
                                    <td><FontAwesomeIcon className="red" icon={faTimes} /><FontAwesomeIcon className="green" icon={faCheck} /><Link to="/components/candidate-profile"> <FontAwesomeIcon className="brown" icon={faEye} /></Link><FontAwesomeIcon className="red" icon={faDownload} /><FontAwesomeIcon className="ornge" icon={faTrashAlt} /></td>
                                </tr>
                        </tbody>
                    </Table>
                </Card>
            </>
        );
    };
};
export default dashboard;