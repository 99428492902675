
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faChartLine, faCloudUploadAlt, faEdit, faTrashAlt, faDownload, faThumbsUp, faPlus, faRocket, faTasks, faUserShield, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Span, Dropdown, ButtonGroup, Card, Nav, Table, Form } from '@themesberg/react-bootstrap';
import { PageVisitsTable } from "../../components/Tables";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Api from '../../service/api';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
class dashboard extends React.Component {
    constructor() {
        super();
        this.state = {
            readOnly: true,
            arrayOfDivs: [],
            divID: 1,
            arrayOfDivs1: [],
            divID1: 1,
            JobFeilds: [],
            StreamByFeildsId: [],
            fieldId: "",
            errorTextfieldId: "",
            name: "",
            errorTextname: "",
            SelectfieldId:"",
            editabledetails:[]
        }
    }
    componentDidMount() {
        this.getJobsFeildList()
        // this.getStreamByFeildList()
    }
    getJobsFeildList = () => {
        Api.getalljobfeilds().then(
            res => {
                if (res.data.status == "success") {
                    console.log(res.data.data, "resss")
                    this.setState({
                        JobFeilds: res.data.data,
                        SelectfieldId : res.data.data[0]._id
                    })
                    this.getStreamByFeildList(res.data.data[0]._id)
                } else {
                    console.log("error")
                }
            })
    }
    getStreamByFeildList = (id) => {
        Api.getallstreambyfeildId(id).then(
            res => {
                console.log(res.data.data, "resList")
                if (res.data.status == "success") {
                    console.log(res.data.data, "resss")
                    this.setState({
                        StreamByFeildsId: res.data.data,
                    })
                } else {
                    console.log("error")
                }
            })
    }
    onClickButton = (e) => {
        this.setState({ openModal: true })
    }
    onCloseModal = () => {
        this.setState({ openModal: false })
    }
    onClickButton1 = (e) => {
        console.log(e)
        this.setState({ openModal1: true,
        editabledetails : e,
        })
    }
    onCloseModal1 = () => {
        this.setState({ openModal1: false })
    }
    onChangeOffeild = (event) => {
        console.log(event.target.value)
        let feildVal = event.target.value;
        if (feildVal === "") {
            this.setState({
                errorTextfieldId: "Enter Email",
                isValid: false
            })
        }
        else {
            this.setState({
                errorTextfieldId: "",
                fieldId: event.target.value,
                isValid: true
            })
        }
    }

    onChangeofname = (event) => {
        let nameval = event.target.value;
        if (nameval === "") {
            this.setState({
                errorTextname: "Enter Password ",
                isValid: false
            })
        }
        else {
            this.setState({
                errorTextname: "",
                name: event.target.value,
                isValid: true
            })
        }
    }
    onChangeseletectOffeild = (event) => {
        console.log(event.target.value)
        let feildVal = event.target.value;
        if (feildVal === "") {
            this.setState({
                errorTextfieldId: "Enter Email",
                isValid: false
            })
        }
        else {
            this.setState({
                errorTextfieldId: "",
                SelectfieldId: event.target.value,
                isValid: true
            })
            this.getStreamByFeildList(event.target.value)
        }
    }
    validateInput() {
        let errorTextfieldId = '';
        let errorTextname = ''
        let isValid = true;

        if (this.state.name.length === 0) {
            errorTextname = 'Enter Email';
            isValid = false;
        }
        if (this.state.fieldId === 0) {
            errorTextfieldId = 'Enter Password';
            isValid = false;
        }

        this.setState({
            ...this.state,
            errorTextname,
            errorTextfieldId,

        })
        return isValid;
    }
    submitForm(event) {
        console.log("sdkjf")
        if (this.validateInput()) {
            let data = {
                "name": this.state.name,
                "fieldId": this.state.fieldId,
            }
            Api.addjobstreamdetails(data).then(
                res => {
                    console.log(res.data, "res")
                    if (res.data.status == "success") {
                        toast.success('Stream Details added Successfully', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        })
                        this.onCloseModal()
                        // window.location.reload();
                        this.getStreamByFeildList()
                        this.getJobsFeildList()
                    } else {
                        console.log("error",);
                        this.onCloseModal();
                        this.getJobsFeildList()
                        this.getStreamByFeildList()
                        // window.location.reload();

                        toast.error('Something Went Wrong', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        })
                    }
                }

            ).then(error => {
                console.log(error, "error")
            })
                .catch(error => {
                    console.log(error, "error")
                    this.onCloseModal();
                    this.getJobsFeildList()
                    window.location.reload();
                    toast.error('Something Went Wrong', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });

        }
    }
    onChangeOfeditName = (event) => {
        let nameVal = event.target.value;
        if (nameVal === "") {
          this.setState({
            onNameErrorText: "Enter Organization Name",
            isValid: false
          })
        }
        else {
          this.setState({
            onNameErrorText: "",
            name: event.target.value,
            isValid: true
          })
        }
      }
    Submiteditdetails = ()=> {
        let data = {
            "name": this.state.name ? this.state.name : this.state.editabledetails.name,
            "fieldId": this.state.editabledetails.fieldId,
        }
         Api.updateStreamDetails(data,this.state.editabledetails._id).then(
                res => {
                    console.log(res.data, "res")
                    if (res.data.status == "success") {
                        toast.success('Stream Details Update Successfully', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        })
                        this.onCloseModal()
                        window.location.reload();
                        this.getJobsFeildList()
                        // event.preventDefault();
                    } else {
                        console.log("error",);
                        this.onCloseModal();
                        this.getJobsFeildList()
                        window.location.reload();

                        toast.error('Something Went Wrong', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        })
                    }
                }

            ).then(error => {
                console.log(error, "error")
            })
                .catch(error => {
                    console.log(error, "error")
                    this.onCloseModal();
                    this.getJobsFeildList()
                    window.location.reload();
                    toast.error('Something Went Wrong', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
        console.log(data)
    } 
    submit = (e) => {
        confirmAlert({
            title: 'Delete',
            message: 'Are you sure want to Delete',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.deleteStream(e)
                },
                {
                    label: 'No',
                    onClick: () => alert('Click No')
                }
            ]
        });
    };
    deleteStream = (id) => {
        console.log(id)
        Api.deleteStreamDetails(id._id).then(
            res => {
                console.log(res.data, "res")
                if (res.data.status == "success") {
                    toast.success('Stream Details Deleted Successfully', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    this.onCloseModal()
                    window.location.reload();
                    this.getJobsFeildList()
                } else {
                    console.log("error",);
                    toast.error('Something Went Wrong', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }

        ).then(error => {
            console.log(error, "error")
        })
            .catch(error => {
                console.log(error, "error")
                toast.error('Something Went Wrong', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    }
    render() {
        return (
            <>
                <Card border="light" className="shadow-sm">
                    <Card.Header>
                        <Row className="align-items-center">
                            <Col>
                                <h5>Streams List</h5>
                            </Col>
                            <Col>
                                <Form onSubmit={(e) => { e.preventDefault(); this.submitForm(e) }}>
                                    <Form.Group >
                                        <Form.Select defaultValue="0" onChange={this.onChangeseletectOffeild}>
                                            <option value="1">Selecte Feild List </option>
                                            {this.state.JobFeilds?.map((feild, index) => (<>   <option value={feild._id}>{feild.name}</option> </>))}
                                        </Form.Select>
                                    </Form.Group>
                                </Form></Col>

                            <Col></Col>
                            <Col></Col>
                            <Col className="text-end">
                                <Button variant="secondary" onClick={() => this.onClickButton()} size="sm">Add Streams</Button>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Table responsive className="align-items-center table-flush">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Feild List</th>
                                <th scope="col">Streams Name</th>
                                <th scope="col">Status</th>
                                <th scope="col">Active</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.StreamByFeildsId.map((stream, index) => (<>
                                <tr>
                                    <td>{stream.fieldId.name}</td>
                                    <td>{stream.name}</td>
                                    <td>Active</td>
                                    <td><FontAwesomeIcon onClick={() => this.onClickButton1(stream)} icon={faEdit} cursor="pointer" className="me-2" /> <FontAwesomeIcon onClick={() => this.submit(stream)} icon={faTrashAlt} className="me-2" /></td>
                                </tr>
                            </>))}
                        </tbody>
                    </Table>
                </Card>
                <div>
                    <Modal open={this.state.openModal} onClose={this.onCloseModal}>
                        <h4 className="shareprofile text-center">Add Streams</h4>
                        <Form onSubmit={(e) => { e.preventDefault(); this.submitForm(e) }}>
                            <Form.Group className="mb-3">
                                <Form.Label> Feild list  </Form.Label>
                                <Form.Select defaultValue="0" onChange={this.onChangeOffeild}>
                                    <option value="1">Selecte Feild List </option>
                                    {this.state.JobFeilds?.map((feild, index) => (<>   <option value={feild._id}>{feild.name}</option> </>))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Stream Name</Form.Label>
                                <Form.Control type="text" onChange={this.onChangeofname} placeholder="Enter Stream Name" />
                            </Form.Group>
                            {/* <Form.Group className="mb-3">
                                <Form.Label>Active</Form.Label>
                                <Form.Select>
                                    <option defaultValue>Select status</option>
                                    <option>Active</option>
                                    <option>In Active</option>
                                </Form.Select>
                            </Form.Group> */}
                            <Col className="text-center">
                                <Button variant="secondary text-center" type="submit" size="md"> Submit</Button>
                            </Col>
                        </Form>
                    </Modal>
                </div>

                <div>
                    <Modal open={this.state.openModal1} onClose={this.onCloseModal1}>
                        <h4 className="shareprofile text-center">Edit Streams</h4>
                        <Form>
                            {/* <Form.Group className="mb-3">
                                <Form.Label> Main list  </Form.Label>
                                <Form.Select>
                                    <option defaultValue>Select List</option>
                                    <option>Job Seekers</option>
                                    <option>Degree Studets</option>
                                </Form.Select>
                            </Form.Group> */}
                            <Form.Group className="mb-3">
                                <Form.Label>Stream Name</Form.Label>
                                <Form.Control type="text" defaultValue={this.state.editabledetails?.name ? this.state.editabledetails?.name : this.state.name} onChange={this.onChangeOfeditName} placeholder="Enter Stream Name" />
                            </Form.Group>
                            {/* <Form.Group className="mb-3">
                                <Form.Label>Active</Form.Label>
                                <Form.Select>
                                    <option defaultValue>Select status</option>
                                    <option>Active</option>
                                    <option>In Active</option>
                                </Form.Select>
                            </Form.Group> */}
                            <Col className="text-center">
                                <Button variant="secondary text-center" type="submit" onClick={this.Submiteditdetails} size="md"> Submit</Button>
                            </Col>
                        </Form>
                    </Modal>
                    <ToastContainer />
                </div>
            </>
        );
    };
};
export default dashboard;