import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faCartArrowDown, faChartPie, faSave, faChevronDown, faUserPlus, faClipboard, faCommentDots,faBookmark, faFileAlt, faPlus, faRocket, faStore } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Dropdown, Card, Image, InputGroup, Form, } from '@themesberg/react-bootstrap';
import { ChoosePhotoWidget, ProfileCardWidget } from "../../components/Widgets";
import { GeneralInfoForm } from "../../components/Forms";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import Profile3 from "../../assets/img/team/profile-picture-3.jpg";
import Profile1 from "../../assets/img/team/profile-picture-1.jpg";
import ProfileCover from "../../assets/img/slack.png";
import Coin from "../../assets/img/coin.png";
import group from "../../assets/img/group.png";

export default () => {
    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                {/* <Dropdown>
                    <Dropdown.Toggle as={Button} variant="secondary" className="text-dark me-2">
                        <FontAwesomeIcon icon={faPlus} className="me-2" />
                        <span>New</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2">
                        <Dropdown.Item>
                            <FontAwesomeIcon icon={faFileAlt} className="me-2" /> Document
            </Dropdown.Item>
                        <Dropdown.Item>
                            <FontAwesomeIcon icon={faCommentDots} className="me-2" /> Message
            </Dropdown.Item>
                        <Dropdown.Item>
                            <FontAwesomeIcon icon={faBoxOpen} className="me-2" /> Product
            </Dropdown.Item>

                        <Dropdown.Divider />

                        <Dropdown.Item>
                            <FontAwesomeIcon icon={faRocket} className="text-danger me-2" /> Subscription Plan
              </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> */}

                <div className="d-flex">
                    <Dropdown>
                        <Dropdown.Toggle as={Button} variant="primary">
                            <FontAwesomeIcon icon={faClipboard} className="me-2" /> Fillter
              <span className="icon icon-small ms-1"><FontAwesomeIcon icon={faChevronDown} /></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-1">
                            <Dropdown.Item>
                                <FontAwesomeIcon icon={faBoxOpen} className="me-2" /> Full Time
              </Dropdown.Item>
                            <Dropdown.Item>
                                <FontAwesomeIcon icon={faStore} className="me-2" /> Design
              </Dropdown.Item>
                            <Dropdown.Item>
                                <FontAwesomeIcon icon={faCartArrowDown} className="me-2" /> Remote
              </Dropdown.Item>
                            <Dropdown.Item>
                                <FontAwesomeIcon icon={faChartPie} className="me-2" /> Freelance
              </Dropdown.Item>

                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>

            <Row>
                <Col xs={12} xl={12}>
                    <Row>
                        <Col xs={12}>
                        <Card border="light" className="bg-white shadow-sm mb-4">
                                <Card.Body>
                                    <Row>
                                        <Col className="" lg={1}>
                                            <div className="user-avatar xl-avatar">
                                                <Image fluid rounded src={ProfileCover} />
                                            </div>
                                        </Col>
                                        <Col className="" lg={9}>
                                            <div className="file-field">
                                                <div className="d-flex ms-xl-3">
                                                    <div className="d-flex">
                                                        <input type="file" />
                                                        <div className="d-md-block text-start">
                                                            <div className="fw-normal text-dark mb-1"><h5><b>Junior UI/UX Designer </b></h5></div>
                                                            <div className="text-gray small">Zerozilla Infotech Pvt Ltd</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className={3}>
                                            <div>
                                                <Button variant="light" size="lg" className="me-2">Save Job <span> <FontAwesomeIcon icon={faBookmark} className="me-1" /></span> </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="text-gray subtext">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only.</div>
                                    </Row>
                                    <Row>
                                        <Col lg={{ span: 2, offset: 1 }}>
                                            <div>
                                                <Button variant="light" size="lg" className="me-2 btn100">Full Time </Button>
                                            </div>
                                        </Col>
                                        <Col lg={2}>
                                            <div>
                                                <Button variant="light" size="lg" className="me-2 btn100">Design </Button>
                                            </div>
                                        </Col>
                                        <Col lg={2}>
                                            <div>
                                                <Button variant="light" size="lg" className="me-2 btn100">Remote </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <hr></hr>
                                    <Row>
                                        <Col lg={{ span: 4 }} className="mt-2">
                                            <div className="rupees">
                                                <h4>  <span> <Image fluid rounded src={Coin} width="20px" /></span> ₹15k-30K  <span>/ mon</span></h4>
                                            </div>
                                        </Col>
                                        <Col lg={4} className="mt-2">
                                        <div className="rupees">
                                                <h4> <span> <Image fluid rounded src={group} width="20px" /></span> 55  <span>people applied</span></h4>
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="rupees endbtn">
                                                <Button variant="danger" size="lg" className="me-2 ">View Job </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <Card border="light" className="bg-white shadow-sm mb-4">
                                <Card.Body>
                                    <Row>
                                        <Col className="" lg={1}>
                                            <div className="user-avatar xl-avatar">
                                                <Image fluid rounded src={ProfileCover} />
                                            </div>
                                        </Col>
                                        <Col className="" lg={9}>
                                            <div className="file-field">
                                                <div className="d-flex ms-xl-3">
                                                    <div className="d-flex">
                                                        <input type="file" />
                                                        <div className="d-md-block text-start">
                                                            <div className="fw-normal text-dark mb-1"><h5><b>Junior UI/UX Designer </b></h5></div>
                                                            <div className="text-gray small">Zerozilla Infotech Pvt Ltd</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className={3}>
                                            <div>
                                            <Button  variant="light" size="lg" className="me-2">Save Job<span> <FontAwesomeIcon icon={faBookmark} className="me-1" /></span> </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="text-gray subtext">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only.</div>
                                    </Row>
                                    <Row>
                                        <Col lg={{ span: 2, offset: 1 }}>
                                            <div>
                                                <Button variant="light" size="lg" className="me-2 btn100">Full Time </Button>
                                            </div>
                                        </Col>
                                        <Col lg={2}>
                                            <div>
                                                <Button variant="light" size="lg" className="me-2 btn100">Design </Button>
                                            </div>
                                        </Col>
                                        <Col lg={2}>
                                            <div>
                                                <Button variant="light" size="lg" className="me-2 btn100">Remote </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <hr></hr>
                                    <Row>
                                        <Col lg={{ span: 4 }} className="mt-2">
                                            <div className="rupees">
                                                <h4>  <span> <Image fluid rounded src={Coin} width="20px" /></span> ₹15k-30K  <span>/ mon</span></h4>
                                            </div>
                                        </Col>
                                        <Col lg={4} className="mt-2">
                                        <div className="rupees">
                                                <h4> <span> <Image fluid rounded src={group} width="20px" /></span> 55  <span>people applied</span></h4>
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="rupees endbtn">
                                                <Button href="#/components/viewjobs/" variant="danger" size="lg" className="me-2 ">View Job1 </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <Card border="light" className="bg-white shadow-sm mb-4">
                                <Card.Body>
                                    <Row>
                                        <Col className="" lg={1}>
                                            <div className="user-avatar xl-avatar">
                                                <Image fluid rounded src={ProfileCover} />
                                            </div>
                                        </Col>
                                        <Col className="" lg={9}>
                                            <div className="file-field">
                                                <div className="d-flex ms-xl-3">
                                                    <div className="d-flex">
                                                        <input type="file" />
                                                        <div className="d-md-block text-start">
                                                            <div className="fw-normal text-dark mb-1"><h5><b>Junior UI/UX Designer </b></h5></div>
                                                            <div className="text-gray small">Zerozilla Infotech Pvt Ltd</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className={3}>
                                            <div>
                                                <Button variant="light" size="lg" className="me-2">Save Job <span> <FontAwesomeIcon icon={faBookmark} className="me-1" /></span> </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="text-gray subtext">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only.</div>
                                    </Row>
                                    <Row>
                                        <Col lg={{ span: 2, offset: 1 }}>
                                            <div>
                                                <Button variant="light" size="lg" className="me-2 btn100">Full Time </Button>
                                            </div>
                                        </Col>
                                        <Col lg={2}>
                                            <div>
                                                <Button variant="light" size="lg" className="me-2 btn100">Design </Button>
                                            </div>
                                        </Col>
                                        <Col lg={2}>
                                            <div>
                                                <Button variant="light" size="lg" className="me-2 btn100">Remote </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <hr></hr>
                                    <Row>
                                        <Col lg={{ span: 4 }} className="mt-2">
                                            <div className="rupees">
                                                <h4>  <span> <Image fluid rounded src={Coin} width="20px" /></span> ₹15k-30K  <span>/ mon</span></h4>
                                            </div>
                                        </Col>
                                        <Col lg={4} className="mt-2">
                                        <div className="rupees">
                                                <h4> <span> <Image fluid rounded src={group} width="20px" /></span> 55  <span>people applied</span></h4>
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="rupees endbtn">
                                                <Button variant="danger" size="lg" className="me-2 ">View Job </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <Card border="light" className="bg-white shadow-sm mb-4">
                                <Card.Body>
                                    <Row>
                                        <Col className="" lg={1}>
                                            <div className="user-avatar xl-avatar">
                                                <Image fluid rounded src={ProfileCover} />
                                            </div>
                                        </Col>
                                        <Col className="" lg={9}>
                                            <div className="file-field">
                                                <div className="d-flex ms-xl-3">
                                                    <div className="d-flex">
                                                        <input type="file" />
                                                        <div className="d-md-block text-start">
                                                            <div className="fw-normal text-dark mb-1"><h5><b>Junior UI/UX Designer </b></h5></div>
                                                            <div className="text-gray small">Zerozilla Infotech Pvt Ltd</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className={3}>
                                            <div>
                                                <Button variant="light" size="lg" className="me-2">Save Job <span> <FontAwesomeIcon icon={faBookmark} className="me-1" /></span> </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="text-gray subtext">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only.</div>
                                    </Row>
                                    <Row>
                                        <Col lg={{ span: 2, offset: 1 }}>
                                            <div>
                                                <Button variant="light" size="lg" className="me-2 btn100">Full Time </Button>
                                            </div>
                                        </Col>
                                        <Col lg={2}>
                                            <div>
                                                <Button variant="light" size="lg" className="me-2 btn100">Design </Button>
                                            </div>
                                        </Col>
                                        <Col lg={2}>
                                            <div>
                                                <Button variant="light" size="lg" className="me-2 btn100">Remote </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <hr></hr>
                                    <Row>
                                        <Col lg={{ span: 4 }} className="mt-2">
                                            <div className="rupees">
                                                <h4>  <span> <Image fluid rounded src={Coin} width="20px" /></span> ₹15k-30K  <span>/ mon</span></h4>
                                            </div>
                                        </Col>
                                        <Col lg={4} className="mt-2">
                                        <div className="rupees">
                                                <h4> <span> <Image fluid rounded src={group} width="20px" /></span> 55  <span>people applied</span></h4>
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="rupees endbtn">
                                                <Button variant="danger" size="lg" className="me-2 ">View Job </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <Card border="light" className="bg-white shadow-sm mb-4">
                                <Card.Body>
                                    <Row>
                                        <Col className="" lg={1}>
                                            <div className="user-avatar xl-avatar">
                                                <Image fluid rounded src={ProfileCover} />
                                            </div>
                                        </Col>
                                        <Col className="" lg={9}>
                                            <div className="file-field">
                                                <div className="d-flex ms-xl-3">
                                                    <div className="d-flex">
                                                        <input type="file" />
                                                        <div className="d-md-block text-start">
                                                            <div className="fw-normal text-dark mb-1"><h5><b>Junior UI/UX Designer </b></h5></div>
                                                            <div className="text-gray small">Zerozilla Infotech Pvt Ltd</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className={3}>
                                            <div>
                                                <Button variant="light" size="lg" className="me-2">Save Job <span> <FontAwesomeIcon icon={faBookmark} className="me-1" /></span> </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="text-gray subtext">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only.</div>
                                    </Row>
                                    <Row>
                                        <Col lg={{ span: 2, offset: 1 }}>
                                            <div>
                                                <Button variant="light" size="lg" className="me-2 btn100">Full Time </Button>
                                            </div>
                                        </Col>
                                        <Col lg={2}>
                                            <div>
                                                <Button variant="light" size="lg" className="me-2 btn100">Design </Button>
                                            </div>
                                        </Col>
                                        <Col lg={2}>
                                            <div>
                                                <Button variant="light" size="lg" className="me-2 btn100">Remote </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <hr></hr>
                                    <Row>
                                        <Col lg={{ span: 4 }} className="mt-2">
                                            <div className="rupees">
                                                <h4>  <span> <Image fluid rounded src={Coin} width="20px" /></span> ₹15k-30K  <span>/ mon</span></h4>
                                            </div>
                                        </Col>
                                        <Col lg={4} className="mt-2">
                                        <div className="rupees">
                                                <h4> <span> <Image fluid rounded src={group} width="20px" /></span> 55  <span>people applied</span></h4>
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="rupees endbtn">
                                                <Button variant="danger" size="lg" className="me-2 ">View Job </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <Card border="light" className="bg-white shadow-sm mb-4">
                                <Card.Body>
                                    <Row>
                                        <Col className="" lg={1}>
                                            <div className="user-avatar xl-avatar">
                                                <Image fluid rounded src={ProfileCover} />
                                            </div>
                                        </Col>
                                        <Col className="" lg={9}>
                                            <div className="file-field">
                                                <div className="d-flex ms-xl-3">
                                                    <div className="d-flex">
                                                        <input type="file" />
                                                        <div className="d-md-block text-start">
                                                            <div className="fw-normal text-dark mb-1"><h5><b>Junior UI/UX Designer </b></h5></div>
                                                            <div className="text-gray small">Zerozilla Infotech Pvt Ltd</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className={3}>
                                            <div>
                                                <Button variant="light" size="lg" className="me-2">Save Job <span> <FontAwesomeIcon icon={faBookmark} className="me-1" /></span> </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="text-gray subtext">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only.</div>
                                    </Row>
                                    <Row>
                                        <Col lg={{ span: 2, offset: 1 }}>
                                            <div>
                                                <Button variant="light" size="lg" className="me-2 btn100">Full Time </Button>
                                            </div>
                                        </Col>
                                        <Col lg={2}>
                                            <div>
                                                <Button variant="light" size="lg" className="me-2 btn100">Design </Button>
                                            </div>
                                        </Col>
                                        <Col lg={2}>
                                            <div>
                                                <Button variant="light" size="lg" className="me-2 btn100">Remote </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <hr></hr>
                                    <Row>
                                        <Col lg={{ span: 4 }} className="mt-2">
                                            <div className="rupees">
                                                <h4>  <span> <Image fluid rounded src={Coin} width="20px" /></span> ₹15k-30K  <span>/ mon</span></h4>
                                            </div>
                                        </Col>
                                        <Col lg={4} className="mt-2">
                                        <div className="rupees">
                                                <h4> <span> <Image fluid rounded src={group} width="20px" /></span> 55  <span>people applied</span></h4>
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="rupees endbtn">
                                                <Button variant="danger" size="lg" className="me-2 ">View Job1 </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};
