import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faCartArrowDown, faChartPie, faSave, faChevronDown, faUserPlus, faClipboard, faCommentDots, faBookmark, faFileAlt, faPlus, faRocket, faStore } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Dropdown, Card, Image, InputGroup, Form, } from '@themesberg/react-bootstrap';
import { ChoosePhotoWidget, ProfileCardWidget } from "../../components/Widgets";
import { GeneralInfoForm } from "../../components/Forms";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import Profile3 from "../../assets/img/team/profile-picture-3.jpg";
import Profile1 from "../../assets/img/team/profile-picture-1.jpg";
import ProfileCover from "../../assets/img/slack.png";
import Coin from "../../assets/img/coin.png";
import group from "../../assets/img/group.png";
import fivestar from "../../assets/img/fivestar.jpeg";
import verified from "../../assets/img/verified.jpeg";
export default () => {
    return (
        <>
            <Row>
                <Col xs={12} xl={12}>
                    <Row>
                        <Col xs={12}>
                            <Card border="light" className="bg-white shadow-sm mb-4">
                                <Card.Body>
                                    <Row>
                                        <Col className="" lg={1}>
                                            <div className="user-avatar xl-avatar">
                                                <Image fluid rounded src={ProfileCover} />
                                            </div>
                                        </Col>
                                        <Col className="" lg={10}>
                                            <div className="file-field">
                                                <div className="d-flex ms-xl-3">
                                                    <div className="d-flex">
                                                        <input type="file" />
                                                        <div className="d-md-block text-start">
                                                            <div className="fw-normal text-dark mb-1"><h5><b>Looking For Figma Desinger For Gaming Theme With potential long term position </b></h5></div>
                                                            <div className="text-gray small">Zerozilla Infotech Pvt Ltd</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className={1}>
                                            <div>
                                                <Button variant="light" size="lg" className="me-2"> <span> <FontAwesomeIcon icon={faBookmark} className="me-1" /></span> </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <div className="text-gray subtext"><h5><b>Job Description </b></h5>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only.</div>
                                            {/* <div className="text-gray subtext1">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard </div>
                                        <div className="text-gray subtext1">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard </div>
                                        <div className="text-gray subtext1">
                                        Start would be immediately </div> */}
                                        </Col>

                                    </Row>
                                    <Row style={{padding:"0px 10px"}}>
                                        <Col lg={4} className="text-gray subtext" >
                                            <h6><b>Job Designation : </b> Marketing Manager</h6>
                                            <h6><b>Education Qualification : </b>BE (CS)</h6>
                                            <h6><b>Job Type : </b>Full Time</h6>
                                            <h6><b>Language : </b>Kannada, Hindi, English </h6>
                                            <h6><b>Age : </b>24 / male</h6>

                                        </Col>
                                        <Col lg={4} className="text-gray subtext" >
                                            <h6><b>State : </b>Karanataka </h6>
                                            <h6><b>City : </b>Belagavi </h6>
                                            <h6><b>Salary : </b>25000 </h6>
                                            <h6><b>Job Category : </b>IT</h6>


                                        </Col>
                                        <Col lg={4} className="text-gray subtext">
                                            <h6><b>No Openings : </b>25</h6>
                                            <h6><b>Experince : </b>2+ yr</h6>
                                            <h6><b>District : </b>Belagavi </h6>
                                            <h6><b>Shift : </b>Day (11:00 AM To 6:00 PM) </h6>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="text-gray subtext"><h5><b>Skills and Expertise </b></h5></div>
                                        <Col lg={{ span: 2, }}>
                                            <div>
                                                <Button variant="light" size="sm" className="me-2 btn100">UI Designer </Button>
                                            </div>
                                        </Col>
                                        <Col lg={2}>
                                            <div>
                                                <Button variant="light" size="sm" className="me-2 btn100">Figma </Button>
                                            </div>
                                        </Col>
                                        <Col lg={2}>
                                            <div>
                                                <Button variant="light" size="sm" className="me-2 btn100">Landing page </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <hr></hr>
                                    <Row>
                                        <div className="text-gray subtext1"><h5><b>Detail projects </b></h5></div>
                                        <Col lg={{ span: 4, }}>
                                            <div className="text-gray subtext1">
                                                <h6>5.00 of 48 reviews</h6>
                                                <Image fluid rounded src={fivestar} width="130px" />
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="text-gray subtext1">
                                                <h6>28 success Project.</h6>
                                                <h6> <Image fluid rounded src={verified} width="    30px" />project verified</h6>
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="text-gray subtext1">
                                                <h6>60K+ total Spent</h6>
                                                <h6><Image fluid rounded src={verified} width="30px" /> Payment Verified </h6>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};
