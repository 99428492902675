
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faChartLine, faCloudUploadAlt, faEdit, faTrashAlt, faPlus, faRocket, faTasks, faUserShield, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Span, Dropdown, ButtonGroup, Card, Nav, Table, Form } from '@themesberg/react-bootstrap';
import { PageVisitsTable } from "../../components/Tables";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import Api from '../../service/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class employeeIndustries extends React.Component {
    constructor() {
        super();
        this.state = {
            readOnly: true,
            arrayOfDivs: [],
            divID: 1,
            arrayOfDivs1: [],
            divID1: 1,
            editabledetails:[],
            updatedemploymentIndustry:'',
            errorTextupdatedemploymentIndustry:'',
        }
    }
    componentDidMount() {
        this.getJobsFeildList();
        this.getallemployementIndustries()
    }
    submit = (e) => {
        confirmAlert({
            title: 'Delete',
            message: 'Are you sure want to Delete',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.deleteempindustries(e)
                },
                {
                    label: 'No',
                    onClick: () => alert('Click No')
                }
            ]
        });
    };
    deleteempindustries = (id) => {
        console.log(id)
        let data = {
            "streamCourseId":id.streamCourseId._id,
            "employmentIndustry":id.employmentIndustry
        }
        Api.deleteempindustriesdetails(data,id._id).then(
            res => {
                console.log(res.data, "res")
                if (res.data.status == "success") {
                    toast.success('Stream Details Deleted Successfully', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    this.onCloseModal()
                    window.location.reload();
                } else {
                    console.log("error",);
                    toast.error('Something Went Wrong', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }

        ).then(error => {
            console.log(error, "error")
        })
            .catch(error => {
                console.log(error, "error")
                // toast.error('Something Went Wrong', {
                //     position: "top-right",
                //     autoClose: 5000,
                //     hideProgressBar: true,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                // });
            });
    }
    onClickButton = (e) => {
        this.setState({ openModal: true })
    }
    onCloseModal = () => {
        this.setState({ openModal: false })
    }
    onClickButton1 = (e) => {
        console.log(e)
        this.setState({ openModal1: true ,
            editabledetails: e,})
    }
    onCloseModal1 = () => {
        this.setState({ openModal1: false })
    }
    getJobsFeildList = () => {
        Api.getalljobfeilds().then(
            res => {
                if (res.data.status == "success") {
                    console.log(res.data.data, "resss")
                    this.setState({
                        JobFeilds: res.data.data,
                    })
                } else {
                    console.log("error")
                }
            })
    }
    onChangeseletectOffeild = (event) => {
        console.log(event.target.value)
        let feildVal = event.target.value;
        if (feildVal === "") {
            this.setState({
                errorTextfieldId: "Enter Email",
                isValid: false
            })
        }
        else {
            this.setState({
                errorTextfieldId: "",
                SelectfieldId: event.target.value,
                isValid: true
            })
            this.getStreamByFeildList(event.target.value)
        }
    }
    getStreamByFeildList = (id) => {
        Api.getallstreambyfeildId(id).then(
            res => {
                console.log(res.data.data, "resList")
                if (res.data.status == "success") {
                    console.log(res.data.data, "resss")
                    this.setState({
                        StreamByFeildsId: res.data.data,
                    })
                } else {
                    console.log("error")
                }
            })
    }
    onChangeseletectstream = (event) => {
        console.log(event.target.value)
        let feildVal = event.target.value;
        if (feildVal === "") {
            this.setState({
                errorTextfieldId: "Enter Email",
                isValid: false
            })
        }
        else {
            this.setState({
                errorTextfieldId: "",
                SelectfieldId: event.target.value,
                streamId: event.target.value,
                isValid: true
            })
            this.getStreanBycourse(event.target.value)
        }
    }
    getStreanBycourse = (e) => {
        Api.getallCourseList(e).then(
            res => {
                if (res.data.status == "success") {
                    console.log(res.data.data, "resss")
                    this.setState({
                        CourseList: res.data.data,
                    })
                    this.getJobsFeildList()
                } else {
                    console.log("error")
                }
            })
    }
    onChangeseletectCourse = (event) => {
        console.log(event.target.value)
        let feildVal = event.target.value;
        if (feildVal === "") {
            this.setState({
                errorTextfieldId: "Enter Email",
                isValid: false
            })
        }
        else {
            this.setState({
                errorTextfieldId: "",
                SelectfieldId: event.target.value,
                streamCourseId: event.target.value,
                isValid: true
            })
            this.getallemployementIndustriesByCourseId(event.target.value)
        }
    }
    getallemployementIndustriesByCourseId = (id) => {
        Api.getallemployementIndustriesCourseID(id).then(
          res => {
            if (res.data.status == "success") {
              console.log(res.data.data, "resss")
              this.setState({
                emplyeeindustriesDetails: res.data.data,
              })
            } else {
              console.log("error")
            }
          }) 
      }
    onChangeofempindustries = (event) => {
        console.log(event.target.value)
        let feildVal = event.target.value;
        if (feildVal === "") {
            this.setState({
                errorTextupdatedemploymentIndustry: "Enter Email",
                isValid: false
            })
        }
        else {
            this.setState({
                errorTextupdatedemploymentIndustry: "",
                updatedemploymentIndustry:event.target.value,
                isValid: true
            })
        }
    }
    getallemployementIndustries = () => {
        Api.getallemployementIndustries().then(
          res => {
            if (res.data.status == "success") {
              console.log(res.data.data, "resss")
              this.setState({
                emplyeeindustriesDetails: res.data.data,
              })
            } else {
              console.log("error")
            }
          }) 
      }
      Submiteditdetails = () => {
        let data = {
            // "streamCourseId":this.state.editabledetails.streamCourseId._id,
            "employmentIndustry":this.state.updatedemploymentIndustry ? this.state.updatedemploymentIndustry : this.state.editabledetails.employmentIndustry,
            // "updatedemploymentIndustry":this.state.updatedemploymentIndustry  this.state.editabledetails.employmentIndustry ? this.state.editabledetails.employmentIndustry :
        }
        Api.updateempindustriesdetails(data,this.state.editabledetails._id).then(
            res => {
                console.log(res.data, "res")
                if (res.data.status == "success") {
                    toast.success('Stream Details Update Successfully', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    this.onCloseModal()
                    window.location.reload();
                    this.getJobsFeildList()
                } else {
                    console.log("error",);
                    toast.error('Something Went Wrong', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }

        ).then(error => {
            console.log(error, "error")
        })
            .catch(error => {
                console.log(error, "error")
                toast.error('Something Went Wrong', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
        console.log(data)
    }
    render() {
        return (
            <>
                <Card border="light" className="shadow-sm">
                    <Card.Header>
                        <Row className="align-items-center">
                            <Col>
                                <h5>Employment Industries List</h5>
                            </Col>
                            <Col>
                                <Form >
                                    <Form.Group >
                                        <Form.Select defaultValue="0" onChange={this.onChangeseletectOffeild}>
                                            <option value="1">Selecte Feild List </option>
                                            {this.state.JobFeilds?.map((feild, index) => (<>   <option value={feild._id}>{feild.name}</option> </>))}
                                        </Form.Select>
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col>
                                <Form>
                                    <Form.Group >
                                        <Form.Select defaultValue="0" onChange={this.onChangeseletectstream}>
                                            <option value="1">Selecte Stream </option>
                                            {this.state.StreamByFeildsId?.map((stream, index) => (<>   <option value={stream._id}>{stream.name}</option> </>))}
                                        </Form.Select>
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col>
                                <Form>
                                    <Form.Group >
                                        <Form.Select defaultValue="0" onChange={this.onChangeseletectCourse}>
                                            <option value="1">Selecte Course </option>
                                            {this.state.CourseList?.map((stream, index) => (<>   <option value={stream._id}>{stream.name}</option> </>))}
                                        </Form.Select>
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col className="text-end">
                                {/* <Button variant="secondary" onClick={() => this.onClickButton()} size="sm">Add Employee Industries</Button> */}
                            </Col>
                        </Row>
                    </Card.Header>
                    <Table responsive className="align-items-center table-flush">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Course Name</th>
                                <th scope="col">Employee Industries</th>
                                <th scope="col">Status</th>
                                <th scope="col">Active</th>
                            </tr>
                        </thead>
                        <tbody>
                        {this.state.emplyeeindustriesDetails?.map((emp, index) => (<>
                            <tr>
                        <td>{emp.streamCourseId?.name}</td>
                                <td>{emp.employmentIndustry}</td>
                                <td>Active</td>
                                <td><FontAwesomeIcon onClick={() => this.onClickButton1(emp)} icon={faEdit} cursor="pointer" className="me-2" /> <FontAwesomeIcon onClick={()=> this.submit(emp)} icon={faTrashAlt} className="me-2" /></td>
                            </tr>
                            </>
                        ))}
                        </tbody>
                    </Table>
                    <ToastContainer />
                </Card>
                <div>
                    <Modal open={this.state.openModal} onClose={this.onCloseModal}>
                        <h4 className="shareprofile text-center">Add courses</h4>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label> Select Course  </Form.Label>
                                <Form.Select>
                                    <option defaultValue>Select Course</option>
                                    <option>Bcom accountancy</option>
                                    <option>Bsc Maths</option>
                                    <option>BA Arts</option>
                                    <option>BCom Economics</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Employee Industries</Form.Label>
                                <Form.Control type="text" placeholder="Enter Employee Industries" />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Active</Form.Label>
                                <Form.Select>
                                    <option defaultValue>Select status</option>
                                    <option>Active</option>
                                    <option>In Active</option>
                                </Form.Select>
                            </Form.Group>
                            <Col className="text-center">
                                <Button variant="secondary text-center" size="md"> Submit</Button>
                            </Col>
                        </Form>
                    </Modal>
                </div>

                <div>
                    <Modal open={this.state.openModal1} onClose={this.onCloseModal1}>
                        <h4 className="shareprofile text-center">Edit Course</h4>
                        <Form>
                           
                            <Form.Group className="mb-3">
                                <Form.Label>Employee Industries</Form.Label>
                                <Form.Control type="text" defaultValue={this.state.editabledetails?.employmentIndustry ? this.state.editabledetails?.employmentIndustry : this.state.updatedemploymentIndustry}  onChange={this.onChangeofempindustries}  placeholder="Enter Employee Industries" />
                            </Form.Group>
                            <Col className="text-center">
                                <Button variant="secondary text-center" onClick={this.Submiteditdetails} size="md"> Submit</Button>
                            </Col>
                        </Form>
                    </Modal>
                </div>
            </>
        );
    };
};
export default employeeIndustries;