
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCashRegister, faChartLine, faCloudUploadAlt, faPlus, faRocket, faTasks, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Dropdown, ButtonGroup, Card, ListGroup, } from '@themesberg/react-bootstrap';
import ProfileCover from "../../assets/img/blue.png";
import Green from "../../assets/img/green.png";
import Cho from "../../assets/img/cho.png";
import Purple from "../../assets/img/purple.png";
import { CounterWidget, CircleChartWidget, BarChartWidget, CounterCompanyWidget, TeamMembersWidget, ProgressTrackWidget, RankingWidget, SalesValueWidget, SalesValueWidgetPhone, AcquisitionWidget } from "../../components/Widgets";
import { PageVisitsTable } from "../../components/Tables";
import { trafficShares, totalOrders } from "../../data/charts";
import { Link } from 'react-router-dom';
class dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            organizationId: '',
        };

    }
    componentDidMount() {
    }

    render() {

        return (
            <>
                <Row className="align-items-center">
                    <Col>
                        <h5>Streams List</h5>
                    </Col>
                    <Col>
                        </Col>

                    <Col></Col>
                    <Col></Col>
                    <Col className="text-end">
                    </Col>
                </Row>
                <Row className="justify-content-md-center mt-5">
                    <Col xs={12} sm={6} xl={6} className="mb-4">
                        <CounterCompanyWidget
                            category="Bubble Studios"
                            title='Desgin Team Agency'
                            percentage={"Manchester, England"}
                            icon={ProfileCover}
                            iconColor="shape-secondary"
                        />
                    </Col>
                    <Col xs={12} sm={6} xl={6} className="mb-4">
                        <CounterCompanyWidget
                            category="Bubble Studios"
                            title='Desgin Team Agency'
                            percentage={"Manchester, England"}
                            icon={Cho}
                            iconColor="shape-secondary"
                        />
                    </Col>
                    <Col xs={12} sm={6} xl={6} className="mb-4">
                        <CounterCompanyWidget
                            category="Bubble Studios"
                            title='Desgin Team Agency'
                            percentage={"Manchester, England"}
                            icon={Green}
                            iconColor="shape-secondary"
                        />
                    </Col>
                    <Col xs={12} sm={6} xl={6} className="mb-4">
                        <CounterCompanyWidget
                            category="Bubble Studios"
                            title='Desgin Team Agency'
                            percentage={"Manchester, England"}
                            icon={Purple}
                            iconColor="shape-secondary"
                        />
                    </Col>

                </Row>
                <Row className="justify-content-md-center mt-0">
                    <Col xs={12} sm={6} xl={6} className="mb-4">
                        <CounterCompanyWidget
                            category="Bubble Studios"
                            title='Desgin Team Agency'
                            percentage={"Manchester, England"}
                            icon={ProfileCover}
                            iconColor="shape-secondary"
                        />
                    </Col>
                    <Col xs={12} sm={6} xl={6} className="mb-4">
                        <CounterCompanyWidget
                            category="Bubble Studios"
                            title='Desgin Team Agency'
                            percentage={"Manchester, England"}
                            icon={Cho}
                            iconColor="shape-secondary"
                        />
                    </Col>
                    <Col xs={12} sm={6} xl={6} className="mb-4">
                        <CounterCompanyWidget
                            category="Bubble Studios"
                            title='Desgin Team Agency'
                            percentage={"Manchester, England"}
                            icon={Green}
                            iconColor="shape-secondary"
                        />
                    </Col>
                    <Col xs={12} sm={6} xl={6} className="mb-4">
                        <CounterCompanyWidget
                            category="Bubble Studios"
                            title='Desgin Team Agency'
                            percentage={"Manchester, England"}
                            icon={Purple}
                            iconColor="shape-secondary"
                        />
                    </Col>

                </Row>
            </>
        );
    };
}
export default dashboard;

