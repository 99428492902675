
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faChartLine, faCloudUploadAlt, faEdit, faTrashAlt, faDownload, faThumbsUp, faPlus, faRocket, faTasks, faUserShield, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Span, Dropdown, ButtonGroup, Card, Nav, Table, Form } from '@themesberg/react-bootstrap';
import { PageVisitsTable } from "../../components/Tables";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Api from '../../service/api';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
class dashboard extends React.Component {
    constructor() {
        super();
        this.state = {
            readOnly: true,
            arrayOfDivs: [],
            divID: 1,
            arrayOfDivs1: [],
            divID1: 1,
            DyanmicCard: [],
            StreamByFeildsId: [],
            fieldId: "",
            errorTextfieldId: "",
            name: "",
            errorTextname: "",
            SelectfieldId: "",
            editabledetails: [],
            errorTextsoftSkill: "",
            errorTextRelatedSkill: "",
            softSkill: "",
            RelatedSkill: "",
        }
    }
    componentDidMount() {
        this.getDynamicCardList()
    }
    getDynamicCardList = () => {
        Api.getDynamicCardListDetails().then(
            res => {
                if (res.data.status == "success") {
                    console.log(res.data.data, "resss")
                    this.setState({
                        DyanmicCard: res.data.data,
                    })
                    this.getJobsFeildList()
                } else {
                    console.log("error")
                }
            })
    }
    getJobsFeildList = () => {
        Api.getalljobfeilds().then(
            res => {
                if (res.data.status == "success") {
                    console.log(res.data.data, "resss")
                    this.setState({
                        JobFeilds: res.data.data,
                    })
                } else {
                    console.log("error")
                }
            })
    }
    onChangeseletectstream = (event) => {
        console.log(event.target.value)
        let feildVal = event.target.value;
        if (feildVal === "") {
            this.setState({
                errorTextfieldId: "Enter Email",
                isValid: false
            })
        }
        else {
            this.setState({
                errorTextfieldId: "",
                SelectfieldId: event.target.value,
                streamId: event.target.value,
                isValid: true
            })
            this.getStreanBycourse(event.target.value)
        }
    }
    onChangeseletectCourse = (event) => {
        console.log(event.target.value)
        let feildVal = event.target.value;
        if (feildVal === "") {
            this.setState({
                errorTextfieldId: "Enter Email",
                isValid: false
            })
        }
        else {
            this.setState({
                errorTextfieldId: "",
                SelectfieldId: event.target.value,
                streamCourseId: event.target.value,
                isValid: true
            })
            this.getDynamicCardListbyID(event.target.value)
        }
    }
    getDynamicCardListbyID = (id) => {
        Api.getDynamicCardListDetailsCourseID(id).then(
            res => {
                if (res.data.status == "success") {
                    console.log(res.data.data, "resss")
                    this.setState({
                        DyanmicCard: res.data.data,
                    })
                    this.getJobsFeildList()
                } else {
                    console.log("error")
                }
            })
    }
    onchnageofRelatedSkill = (event) => {
        console.log(event.target.value)
        let RelatedSkillVal = event.target.value;
        if (RelatedSkillVal === "") {
            this.setState({
                errorTextRelatedSkill: "Enter Email",
                isValid: false
            })
        }
        else {
            this.setState({
                errorTextRelatedSkill: "",
                relatedSkill: event.target.value,
                isValid: true
            })
        }
    }
    onchnageofsoftSkill = (event) => {
        console.log(event.target.value)
        let softSkillVal = event.target.value;
        if (softSkillVal === "") {
            this.setState({
                errorTextsoftSkill: "Enter Email",
                isValid: false
            })
        }
        else {
            this.setState({
                errorTextsoftSkill: "",
                softSkill: event.target.value,
                isValid: true
            })
        }
    }
    getStreanBycourse = (e) => {
        Api.getallCourseList(e).then(
            res => {
                if (res.data.status == "success") {
                    console.log(res.data.data, "resss")
                    this.setState({
                        CourseList: res.data.data,
                    })
                    this.getJobsFeildList()
                } else {
                    console.log("error")
                }
            })
    }
    onChangeseletectOffeild = (event) => {
        console.log(event.target.value)
        let feildVal = event.target.value;
        if (feildVal === "") {
            this.setState({
                errorTextfieldId: "Enter Email",
                isValid: false
            })
        }
        else {
            this.setState({
                errorTextfieldId: "",
                SelectfieldId: event.target.value,
                isValid: true
            })
            this.getStreamByFeildList(event.target.value)
        }
    }
    onchnageofdescription = (event) => {
        console.log(event.target.value)
        let descriptionVal = event.target.value;
        if (descriptionVal === "") {
            this.setState({
                errorTextdescription: "Enter Email",
                isValid: false
            })
        }
        else {
            this.setState({
                errorTextdescription: "",
                description: event.target.value,
                isValid: true
            })
        }
    }
    getStreamByFeildList = (id) => {
        Api.getallstreambyfeildId(id).then(
            res => {
                console.log(res.data.data, "resList")
                if (res.data.status == "success") {
                    console.log(res.data.data, "resss")
                    this.setState({
                        StreamByFeildsId: res.data.data,
                    })
                } else {
                    console.log("error")
                }
            })
    }
    onClickButton = (e) => {
        this.setState({ openModal: true })
    }
    onCloseModal = () => {
        this.setState({ openModal: false })
    }
    onClickButton1 = (e) => {
        console.log(e)
        this.setState({
            openModal1: true,
            editabledetails: e,
        })
    }
    onCloseModal1 = () => {
        this.setState({ openModal1: false })
    }
    onChangeOffeild = (event) => {
        console.log(event.target.value)
        let feildVal = event.target.value;
        if (feildVal === "") {
            this.setState({
                errorTextfieldId: "Enter Email",
                isValid: false
            })
        }
        else {
            this.setState({
                errorTextfieldId: "",
                fieldId: event.target.value,
                isValid: true
            })
        }
    }

    onChangeofname = (event) => {
        let nameval = event.target.value;
        if (nameval === "") {
            this.setState({
                errorTextname: "Enter Password ",
                isValid: false
            })
        }
        else {
            this.setState({
                errorTextname: "",
                name: event.target.value,
                isValid: true
            })
        }
    }
    validateInput() {
        let errorTextsoftSkill = "";
        let errorTextRelatedSkill = "";
        let isValid = true;
        if (this.state.softSkill === 0) {
            errorTextsoftSkill = 'Enter Password';
            isValid = false;
        }
        if (this.state.RelatedSkill === 0) {
            errorTextRelatedSkill = 'Enter Password';
            isValid = false;
        }

        this.setState({
            ...this.state,
            errorTextsoftSkill,
            errorTextRelatedSkill,

        })
        return isValid;
    }
    submitForm(event) {
        console.log("sdkjf")
        if (this.validateInput()) {
            let data = {
                "streamCourseId": this.state.streamCourseId,
                "description": this.state.description,
                "relatedSkill": this.state.relatedSkill,
                "softSkill": this.state.softSkill,
            }
            Api.adddynamicCarddetails(data).then(
                res => {
                    console.log(res.data, "res")
                    if (res.data.status == "success") {
                        toast.success('Dyanmic card Details added Successfully', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        })
                        this.onCloseModal()
                        window.location.reload();
                        event.preventDefault();
                    } else {
                        console.log("error",);
                        // this.onCloseModal();
                        // this.getJobsFeildList()
                        // window.location.reload();

                        toast.error('Something Went Wrong', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        })
                    }
                }

            ).then(error => {
                console.log(error, "error")
            })
                .catch(error => {
                    console.log(error, "error")
                    // this.onCloseModal();
                    // this.getJobsFeildList()
                    // window.location.reload();
                    toast.error('Something Went Wrong', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });

        }
    }
    onChangeOfeditName = (event) => {
        let nameVal = event.target.value;
        if (nameVal === "") {
            this.setState({
                onNameErrorText: "Enter Organization Name",
                isValid: false
            })
        }
        else {
            this.setState({
                onNameErrorText: "",
                name: event.target.value,
                isValid: true
            })
        }
    }
    Submiteditdetails = () => {
        let data = {
            "streamCourseId": this.state.streamCourseId ? this.state.streamCourseId : this.state.editabledetails.streamCourseId,
            "description": this.state.description ? this.state.description : this.state.editabledetails.description,
            "relatedSkill": this.state.relatedSkill ? this.state.relatedSkill : this.state.editabledetails.relatedSkill,
            "softSkill": this.state.softSkill ? this.state.softSkill : this.state.editabledetails.softSkill,
        }
        Api.updateDynamiccardDetails(data, this.state.editabledetails._id).then(
            res => {
                console.log(res.data, "res")
                if (res.data.status == "success") {
                    toast.success('Stream Details Update Successfully', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    this.onCloseModal()
                    window.location.reload();
                    this.getJobsFeildList()
                    // event.preventDefault();
                } else {
                    console.log("error",);
                    // this.onCloseModal();
                    // this.getJobsFeildList()
                    // window.location.reload();

                    toast.error('Something Went Wrong', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }

        ).then(error => {
            console.log(error, "error")
        })
            .catch(error => {
                console.log(error, "error")
                // this.onCloseModal();
                // this.getJobsFeildList()
                // window.location.reload();
                toast.error('Something Went Wrong', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
        console.log(data)
    }
    submit = (e) => {
        confirmAlert({
            title: 'Delete',
            message: 'Are you sure want to Delete',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.deleteStream(e)
                },
                {
                    label: 'No',
                    onClick: () => alert('Click No')
                }
            ]
        });
    };
    deleteStream = (id) => {
        console.log(id)
        Api.deletedyanmicCarddetails(id._id).then(
            res => {
                console.log(res.data, "res")
                if (res.data.status == "success") {
                    toast.success('Stream Details Deleted Successfully', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    this.onCloseModal()
                    window.location.reload();
                    this.getJobsFeildList()
                } else {
                    console.log("error",);
                    toast.error('Something Went Wrong', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }

        ).then(error => {
            console.log(error, "error")
        })
            .catch(error => {
                console.log(error, "error")
                toast.error('Something Went Wrong', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    }
    render() {
        return (
            <>
                <Card border="light" className="shadow-sm">
                    <Card.Header>
                        <Row className="align-items-center">
                            <Col>
                                <h5>Dyanamic Card List</h5>
                            </Col>
                            <Col>
                                <Form >
                                    <Form.Group >
                                        <Form.Select defaultValue="0" onChange={this.onChangeseletectOffeild}>
                                            <option value="1">Selecte Feild List </option>
                                            {this.state.JobFeilds?.map((feild, index) => (<>   <option value={feild._id}>{feild.name}</option> </>))}
                                        </Form.Select>
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col>
                                <Form>
                                    <Form.Group >
                                        <Form.Select defaultValue="0" onChange={this.onChangeseletectstream}>
                                            <option value="1">Selecte Stream </option>
                                            {this.state.StreamByFeildsId?.map((stream, index) => (<>   <option value={stream._id}>{stream.name}</option> </>))}
                                        </Form.Select>
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col>
                                <Form>
                                    <Form.Group >
                                        <Form.Select defaultValue="0" onChange={this.onChangeseletectCourse}>
                                            <option value="1">Selecte Course </option>
                                            {this.state.CourseList?.map((stream, index) => (<>   <option value={stream._id}>{stream.name}</option> </>))}
                                        </Form.Select>
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col className="text-end">
                                <Button variant="secondary" onClick={() => this.onClickButton()} size="sm">Add Dyanamic Card</Button>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Table responsive className="align-items-center table-flush">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Course Id</th>
                                <th scope="col">Description</th>
                                <th scope="col">Related Skill</th>
                                <th scope="col">Soft Skill</th>
                                <th scope="col">Status</th>
                                <th scope="col">Active</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.DyanmicCard.map((card, index) => (<>
                                <tr>
                                    <td>{card.streamCourseId?.name}</td>
                                    <td>{card.description}</td>
                                    <td>{card.relatedSkill}</td>
                                    <td>{card.softSkill}</td>
                                    <td>Active</td>
                                    <td><FontAwesomeIcon onClick={() => this.onClickButton1(card)} icon={faEdit} cursor="pointer" className="me-2" /> <FontAwesomeIcon onClick={() => this.submit(card)} icon={faTrashAlt} className="me-2" /></td>
                                </tr>
                            </>))}
                        </tbody>
                    </Table>
                </Card>
                <div>
                    <Modal open={this.state.openModal} onClose={this.onCloseModal}>
                        <h4 className="shareprofile text-center">Add Streams</h4>
                        <Form onSubmit={(e) => { e.preventDefault(); this.submitForm(e) }}>
                            <Form.Group className="mb-3">
                                <Form.Label>Feild List </Form.Label>
                                <Form.Select defaultValue="0" onChange={this.onChangeseletectOffeild}>
                                    <option value="1">Selecte Feild List </option>
                                    {this.state.JobFeilds?.map((feild, index) => (<>   <option value={feild._id}>{feild.name}</option> </>))}
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Stream Name</Form.Label>
                                <Form.Select defaultValue="0" onChange={this.onChangeseletectstream}>
                                    <option value="1">Selecte Stream </option>
                                    {this.state.StreamByFeildsId?.map((stream, index) => (<>   <option value={stream._id}>{stream.name}</option> </>))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Course Name</Form.Label>
                                <Form.Select defaultValue="0" onChange={this.onChangeseletectCourse}>
                                    <option value="1">Selecte Course </option>
                                    {this.state.CourseList?.map((stream, index) => (<>   <option value={stream._id}>{stream.name}</option> </>))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Description</Form.Label>
                                <Form.Control type="text" onChange={this.onchnageofdescription} placeholder="Enter Description" />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Related Skill</Form.Label>
                                <Form.Control type="text" onChange={this.onchnageofRelatedSkill} placeholder="Enter Related Skill" />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Soft Skill</Form.Label>
                                <Form.Control type="text" onChange={this.onchnageofsoftSkill} placeholder="Enter Soft Skill" />
                            </Form.Group>

                            <Col className="text-center">
                                <Button variant="secondary text-center" type="submit" size="md"> Submit</Button>
                            </Col>
                        </Form>
                    </Modal>
                </div>

                <div>
                    <Modal open={this.state.openModal1} onClose={this.onCloseModal1}>
                        <h4 className="shareprofile text-center">Edit Streams</h4>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Description</Form.Label>
                                <Form.Control type="text" defaultValue={this.state.editabledetails?.description ? this.state.editabledetails?.description : this.state.description} onChange={this.onchnageofdescription} placeholder="Enter Description" />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Related Skill</Form.Label>
                                <Form.Control type="text" onChange={this.onchnageofRelatedSkill} defaultValue={this.state.editabledetails?.relatedSkill ? this.state.editabledetails?.relatedSkill : this.state.relatedSkill} placeholder="Enter Related Skill" />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Soft Skill</Form.Label>
                                <Form.Control type="text" onChange={this.onchnageofsoftSkill} defaultValue={this.state.editabledetails?.softSkill ? this.state.editabledetails?.softSkill : this.state.softSkill} placeholder="Enter Soft Skill" />
                            </Form.Group>

                            <Col className="text-center">
                                <Button variant="secondary text-center" type="submit" onClick={this.Submiteditdetails} size="md"> Submit</Button>
                            </Col>
                        </Form>
                    </Modal>
                    <ToastContainer />
                </div>
            </>
        );
    };
};
export default dashboard;