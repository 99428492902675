
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faChartLine, faCloudUploadAlt, faEdit, faTrashAlt, faPlus, faRocket, faTasks, faUserShield, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Span, Dropdown, ButtonGroup, Card, Nav, Table, Form } from '@themesberg/react-bootstrap';
import { PageVisitsTable } from "../../components/Tables";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Api from '../../service/api';
class skills extends React.Component {
    constructor() {
        super();
        this.state = {
            readOnly: true,
            arrayOfDivs: [],
            divID: 1,
            arrayOfDivs1: [],
            divID1: 1,
            SkillsList: [],
            JobFeilds: [],
            errorTextnmae: "",
            name: '',
            errorTextdescription: "",
            description: "",
            streamId: "",
            streamCourseId: "",
            editabledetails: [],
        }
    }
    componentDidMount() {
        this.getCourseList()
        this.getJobsFeildList()
    }
    getCourseList = (id) => {
        Api.GetAllSkill().then(
            res => {
                if (res.data.status == "success") {
                    console.log(res.data.data, "resss")
                    this.setState({
                        SkillsList: res.data.data,
                    })
                } else {
                    console.log("error")
                }
            })
    }
    getJobsFeildList = () => {
        Api.getalljobfeilds().then(
            res => {
                if (res.data.status == "success") {
                    console.log(res.data.data, "resss")
                    this.setState({
                        JobFeilds: res.data.data,
                    })
                } else {
                    console.log("error")
                }
            })
    }
    getStreamByFeildList = (id) => {
        Api.getallstreambyfeildId(id).then(
            res => {
                console.log(res.data.data, "resList")
                if (res.data.status == "success") {
                    console.log(res.data.data, "resss")
                    this.setState({
                        StreamByFeildsId: res.data.data,
                    })
                } else {
                    console.log("error")
                }
            })
    }
    onChangeseletectOffeild = (event) => {
        console.log(event.target.value)
        let feildVal = event.target.value;
        if (feildVal === "") {
            this.setState({
                errorTextfieldId: "Enter Email",
                isValid: false
            })
        }
        else {
            this.setState({
                errorTextfieldId: "",
                SelectfieldId: event.target.value,
                isValid: true
            })
            this.getStreamByFeildList(event.target.value)
        }
    }
    onChangeseletectstream = (event) => {
        console.log(event.target.value)
        let feildVal = event.target.value;
        if (feildVal === "") {
            this.setState({
                errorTextfieldId: "Enter Email",
                isValid: false
            })
        }
        else {
            this.setState({
                errorTextfieldId: "",
                SelectfieldId: event.target.value,
                streamId: event.target.value,
                isValid: true
            })
            this.getStreanBycourse(event.target.value)
        }
    }
    getStreanBycourse = (e) => {
        Api.getallCourseList(e).then(
            res => {
                if (res.data.status == "success") {
                    console.log(res.data.data, "resss")
                    this.setState({
                        CourseList: res.data.data,
                    })
                    this.getJobsFeildList()
                } else {
                    console.log("error")
                }
            })
    }
    onChangeseletectCourse = (event) => {
        console.log(event.target.value)
        let feildVal = event.target.value;
        if (feildVal === "") {
            this.setState({
                errorTextfieldId: "Enter Email",
                isValid: false
            })
        }
        else {
            this.setState({
                errorTextfieldId: "",
                SelectfieldId: event.target.value,
                streamCourseId: event.target.value,
                isValid: true
            })
            this.getCourseListbycourseId(event.target.value)
        }
    }
    getCourseListbycourseId (e) {
        Api.GetAllSkillByCourseId(e).then(
            res => {
                if (res.data.status == "success") {
                    console.log(res.data.data, "resss")
                    this.setState({
                        SkillsList: res.data.data,
                    })
                } else {
                    console.log("error")
                }
            })  
    }
    onchnageofName = (event) => {
        console.log(event.target.value)
        let nameVal = event.target.value;
        if (nameVal === "") {
            this.setState({
                errorTextnmae: "Enter Email",
                isValid: false
            })
        }
        else {
            this.setState({
                errorTextnmae: "",
                name: event.target.value,
                isValid: true
            })
        }
    }
    onchnageofDescription = (event) => {
        console.log(event.target.value)
        let descriptionVal = event.target.value;
        if (descriptionVal === "") {
            this.setState({
                errorTextdescription: "Enter Email",
                isValid: false
            })
        }
        else {
            this.setState({
                errorTextdescription: "",
                description: event.target.value,
                isValid: true
            })
        }
    }
    onClickButton = (e) => {
        this.setState({ openModal: true })
    }
    onCloseModal = () => {
        this.setState({ openModal: false })
    }
    onClickButton1 = (e) => {
        console.log(e)

        this.setState({
            openModal1: true,
            editabledetails: e
        })
    }
    onCloseModal1 = () => {
        this.setState({ openModal1: false })
    }
    onClickButton2 = (e) => {
        this.setState({
            openModal2: true,
        })
    }
    onCloseModal2 = () => {
        this.setState({ openModal2: false })
    }
    submit = (e) => {
        confirmAlert({
            title: 'Delete',
            message: 'Are you sure want to Delete',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.deleteSkills(e)
                },
                {
                    label: 'No',
                    onClick: () => alert('Click No')
                }
            ]
        });
    };
    deleteSkills = (id) => {
        console.log(id)
        Api.deleteSkillsDetails(id._id).then(
            res => {
                console.log(res.data, "res")
                if (res.data.status == "success") {
                    toast.success('Skills Details Deleted Successfully', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    this.onCloseModal()
                    window.location.reload();
                    this.getJobsFeildList()
                } else {
                    console.log("error",);
                    toast.error('Something Went Wrong', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }

        ).then(error => {
            console.log(error, "error")
        })
            .catch(error => {
                console.log(error, "error")
                toast.error('Something Went Wrong', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    }
    onClickexcelupload = (e) => {
        // console.log(e.target.files[0])
        var fd = new FormData;
        fd.set("file", this.state.file)
        Api.SkillsbulkUpload(fd,this.state.SelectfieldId).then(
            res => {
                console.log(res.data.response, "res")
                if (res.data.response.responseStatus == 1) {
                    toast.success(res.data.response.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                } else {
                    console.log("error",)
                    toast.error('Something Went Wrong', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }

        ).then(error => {
            console.log(error, "error")
        })
            .catch(error => {
                console.log(error, "error")
                toast.error('Something Went Wrong', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    }
    validateInput() {
        let errorTextdescription = '';
        let errorTextnmae = ''
        let isValid = true;

        if (this.state.name.length === 0) {
            errorTextnmae = 'Enter Email';
            isValid = false;
        }
        if (this.state.streamId === 0) {
            errorTextdescription = 'Enter Password';
            isValid = false;
        }

        this.setState({
            ...this.state,
            errorTextnmae,
            errorTextdescription,

        })
        return isValid;
    }
    submitForm(event) {
        console.log("sdkjf")
        if (this.validateInput()) {
            let data = {
                "name": this.state.name,
                "streamId": this.state.streamId,
                "description": this.state.description,
                "streamCourseId": this.state.streamCourseId
            }
            Api.addsingleaddskilldetails(data).then(
                res => {
                    console.log(res.data, "res")
                    if (res.data.status == "success") {
                        toast.success('Skills Details added Successfully', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        })
                        this.onCloseModal()
                        window.location.reload();
                        event.preventDefault();
                    } else {
                        console.log("error",);
                        toast.error('Something Went Wrong', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        })
                    }
                }

            ).then(error => {
                console.log(error, "error")
            })
                .catch(error => {
                    console.log(error, "error")
                    toast.error('Something Went Wrong', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });

        }
    }
    getEditData = (e) => {
        console.log(e)
    }
    Submiteditdetails = () => {
        let data = {
            "name": this.state.name ? this.state.name : this.state.editabledetails.name,
            // "description": this.state.description ? this.state.description : this.state.editabledetails.description,
        }
        Api.updateSkillsDetails(data, this.state.editabledetails._id).then(
            res => {
                console.log(res.data, "res")
                if (res.data.status == "success") {
                    toast.success('Stream Details Update Successfully', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    this.onCloseModal()
                    window.location.reload();
                    this.getJobsFeildList()
                } else {
                    console.log("error",);
                    toast.error('Something Went Wrong', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }

        ).then(error => {
            console.log(error, "error")
        })
            .catch(error => {
                console.log(error, "error")
                toast.error('Something Went Wrong', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
        console.log(data)
    }
    onClickofbulkupload = (e) => {
        console.log(e.target.files[0])
        this.setState({
            errorTextfile: "",
            file: e.target.files[0],
            isValid: true
        })
    }
    render() {
        return (
            <>
                <Card border="light" className="shadow-sm">
                    <Card.Header>
                        <Row className="align-items-center">
                            <Col>
                                <h5>Skills List</h5>
                            </Col>
                            <Col>
                                <Form >
                                    <Form.Group >
                                        <Form.Select defaultValue="0" onChange={this.onChangeseletectOffeild}>
                                            <option value="1">Selecte Feild List </option>
                                            {this.state.JobFeilds?.map((feild, index) => (<>   <option value={feild._id}>{feild.name}</option> </>))}
                                        </Form.Select>
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col>
                                <Form>
                                    <Form.Group >
                                        <Form.Select defaultValue="0" onChange={this.onChangeseletectstream}>
                                            <option value="1">Selecte Stream </option>
                                            {this.state.StreamByFeildsId?.map((stream, index) => (<>   <option value={stream._id}>{stream.name}</option> </>))}
                                        </Form.Select>
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col>
                                <Form>
                                    <Form.Group >
                                        <Form.Select defaultValue="0" onChange={this.onChangeseletectCourse}>
                                            <option value="1">Selecte Course </option>
                                            {this.state.CourseList?.map((stream, index) => (<>   <option value={stream._id}>{stream.name}</option> </>))}
                                        </Form.Select>
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col></Col>
                            <Col lg={3} className="text-end">
                                <React.Fragment>
                                    <div className="d-xl-flex align-items-center">
                                        <div className="file-field">
                                            <div className="d-flex justify-content-xl-center ms-xl-3">
                                                <Button onClick={this.onClickButton2} variant="outline-success" className="m-1 d-flex">
                                                    {/* <span className="icon icon-md">
                                                        <FontAwesomeIcon icon={faDownload} className="me-3" />
                                                    </span> */}
                                                    {/* <input type="file" accept=".xlsx, .xls, .csv" />
                                                    <div className="d-md-block text-start"> */}
                                                        {/* <div className="text-gray small"> */}
                                                            Bulk Upload
                                                            {/* </div> */}
                                                    {/* </div> */}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            </Col>
                            <Col className="text-end">
                                <Button variant="secondary" onClick={() => this.onClickButton()} size="sm">Add Skills</Button>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Table responsive className="align-items-center table-flush">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Course Name</th>
                                <th scope="col">Skills name</th>
                                <th scope="col">Status</th>
                                <th scope="col">Active</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.SkillsList.map((skill, index) => (<>
                                <tr>
                                    <td>{skill.streamCourseId?.name}</td>
                            <td>{skill.name}</td>
                                    <td>Active</td>
                                    <td><FontAwesomeIcon onClick={() => this.onClickButton1(skill)} icon={faEdit} cursor="pointer" className="me-2" /> <FontAwesomeIcon onClick={() => this.submit(skill)} icon={faTrashAlt} className="me-2" /></td>
                                </tr>
                            </>
                            ))}

                            {/* <tr>
                                <td>BSc</td>
                                <td>Maths</td>
                                <td>Active</td>
                                <td><FontAwesomeIcon onClick={() => this.onClickButton1("skill")} icon={faEdit} cursor="pointer" className="me-2" /> <FontAwesomeIcon onClick={() => this.submit("skills")} icon={faTrashAlt} className="me-2" /></td>
                            </tr> */}
                        </tbody>
                    </Table>
                </Card>
                <div>
                    <Modal open={this.state.openModal} onClose={this.onCloseModal}>
                        <h4 className="shareprofile text-center">Add Skills</h4>
                        <Form onSubmit={(e) => { e.preventDefault(); this.submitForm(e) }}>
                            <Form.Group className="mb-3">
                                <Form.Label>Feild List </Form.Label>
                                <Form.Select defaultValue="0" onChange={this.onChangeseletectOffeild}>
                                    <option value="1">Selecte Feild List </option>
                                    {this.state.JobFeilds?.map((feild, index) => (<>   <option value={feild._id}>{feild.name}</option> </>))}
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Stream Name</Form.Label>
                                <Form.Select defaultValue="0" onChange={this.onChangeseletectstream}>
                                    <option value="1">Selecte Stream </option>
                                    {this.state.StreamByFeildsId?.map((stream, index) => (<>   <option value={stream._id}>{stream.name}</option> </>))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Course Name</Form.Label>
                                <Form.Select defaultValue="0" onChange={this.onChangeseletectCourse}>
                                    <option value="1">Selecte Course </option>
                                    {this.state.CourseList?.map((stream, index) => (<>   <option value={stream._id}>{stream.name}</option> </>))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Skills Name</Form.Label>
                                <Form.Control type="text" onChange={this.onchnageofName} placeholder="Enter Skills Name" />
                            </Form.Group>
                            {/* <Form.Group className="mb-3">
                                <Form.Label>Skills Description</Form.Label>
                                <Form.Control type="text" onChange={this.onchnageofDescription} placeholder="Enter Skills Description" />
                            </Form.Group> */}
                            <Col className="text-center">
                                <Button variant="secondary text-center" type="submit" size="md"> Submit</Button>
                            </Col>
                        </Form>
                    </Modal>
                </div>

                <div>
                    <Modal open={this.state.openModal1} onClose={this.onCloseModal1}>
                        <h4 className="shareprofile text-center">Edit Skills</h4>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Skills Name</Form.Label>
                                <Form.Control type="text" defaultValue={this.state.editabledetails?.name ? this.state.editabledetails?.name : this.state.name} onChange={this.onchnageofName}  placeholder="Enter Skills Name" />
                            </Form.Group>
                            {/* <Form.Group className="mb-3">
                                <Form.Label>Skills Description</Form.Label>
                                <Form.Control type="text" placeholder="Enter Skills Description" />
                            </Form.Group> */}
                            <Col className="text-center">
                                <Button variant="secondary text-center" type="submit" onClick={this.Submiteditdetails} size="md"> Submit</Button>
                            </Col>
                        </Form>
                    </Modal>
                    <ToastContainer />
                </div>
                <div>
                    <Modal open={this.state.openModal2} onClose={this.onCloseModal2}>
                        <h4 className="shareprofile text-center">Bulk Upload Course</h4>
                        <Form>
                            <Form.Group >
                                <Form.Label>Select Feild </Form.Label>
                                <Form.Select defaultValue="0" onChange={this.onChangeseletectOffeild}>
                                    <option value="1">Selecte Feild List </option>
                                    {this.state.JobFeilds?.map((feild, index) => (<>   <option value={feild._id}>{feild.name}</option> </>))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Bulk Upload</Form.Label>
                                <Form.Control type="file" accept=".xlsx, .xls, .csv" onChange={this.onClickofbulkupload}></Form.Control>
                            </Form.Group>
                            <Col className="text-center">
                                <Button variant="secondary text-center" onClick={this.onClickexcelupload} size="md"> Submit</Button>
                            </Col>
                        </Form>
                    </Modal>
                    <ToastContainer />
                </div>
            </>
        );
    };
};
export default skills;