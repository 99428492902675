import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faCartArrowDown, faBriefcase, faChevronDown,faDownload, faCheckCircle, faPrint, faShareAlt, faEye, faMapMarker, faPlus, faRocket, faStore } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Card, Dropdown, Image } from '@themesberg/react-bootstrap';
import NotFoundImage from "../../assets/img/team/profile-picture-3.jpg";
import Progress from "../../components/Progress";
export default () => {
    return (
        <>
            <Row>
                <Col xs={12} xl={12}>
                    <Row>
                        <Col xs={12}>
                            <Card border="light" className="bg-white shadow-sm mb-4">
                                <Card.Body>
                                    <Row>
                                        <Col xs={2} className="text-center">
                                            <Image src={NotFoundImage} className="img-fluid w-10" />
                                        </Col>
                                        <Col xs={10}>
                                            <Row>
                                                <Col xs={8}>
                                                    <h5 >Andrew Jonson</h5>
                                                    <small class=""> <FontAwesomeIcon icon={faBriefcase} />&nbsp;&nbsp;example@gmail.com</small>&nbsp;&nbsp;&nbsp; <small class=""> <FontAwesomeIcon icon={faMapMarker} />&nbsp;&nbsp;Indonesia</small>&nbsp;&nbsp;&nbsp; <small class=""> <FontAwesomeIcon icon={faEye} />&nbsp;&nbsp;Manager</small><br></br>
                                                    <small>A data analyst collects, interprets and visualizes data to uncover insights. A data analyst assigns a numerical value to business functions so performance.</small>
                                                </Col>
                                                <Col xs={4} style={{ marginTop: "25px" }}>
                                                    <Progress variant="danger" label="Progress" value={25} />
                                                </Col>
                                            </Row>

                                        </Col>
                                    </Row>
                                    <hr></hr>
                                    <h5 >Description</h5>
                                    <Row>
                                        <Col xl={6} xs={6}>
                                            <div className="userdetails"><h6>Full Name :</h6><span>Andrew Jonson</span></div>
                                            <div className="userdetails"><h6>Resume Title :</h6><span> Searching For PHP Doveloper</span></div>
                                            <div className="userdetails"><h6>Current Designation :</h6><span>PHP Programmer</span></div>
                                            <div className="userdetails"><h6>Annual Salary :</h6><span>$7.5Lacs</span></div>
                                            <div className="userdetails"><h6>Current Company :</h6><span>Abcd pvt Ltd</span></div>
                                            <div className="userdetails"><h6>Experience :</h6><span>3 Yrs</span></div>
                                            <div className="userdetails"><h6>Location :</h6><span>USA</span></div>
                                            <div className="userdetails"><h6>Preferred Location :</h6><span>USA</span></div>
                                            <div className="userdetails"><h6>Qualification :</h6><span>B.Tech(CSE)</span></div>
                                            <div className="userdetails"><h6>Key Skills :</h6><span>Good Communication, Planning and research skills</span></div>
                                        </Col>
                                        <Col xl={6} xs={6}>
                                            <div className="userdetails"><h6>Launguages :</h6><span>English, German, Spanish</span></div>
                                            <div className="userdetails"><h6>Email :</h6><span>andrew@gmail.com</span></div>
                                            <div className="userdetails"><h6>Phone :</h6><span>1234598765</span></div>
                                            <div className="userdetails"><h6>Industry :</h6><span>it Software/ Developer</span></div>
                                            <div className="userdetails"><h6>Date Of Birth :</h6><span>13 June 1996</span></div>
                                            <div className="userdetails"><h6>Gender :</h6><span>Female</span></div>
                                            <div className="userdetails"><h6>Marital Status :</h6><span>Unmarried</span></div>
                                            <div className="userdetails"><h6>Permanent Address :</h6><span>USA</span></div>
                                            <div className="userdetails"><h6>Zip code :</h6><span>12345</span></div>
                                        </Col>
                                    </Row>
                                    <hr></hr>
                                    <Row>
                                        <Col xs={4}>
                                            <div className="userdetails" style={{ marginBottom: "0px" }}><h6>Currently Working at</h6><span>Abcd Pvt Ltd</span></div>
                                            <div className="userdetails" style={{ marginBottom: "0px" }}><h6>3 Yrs Of Working Experience in</h6><span>Abcd Pvt Ltd</span></div>
                                        </Col>
                                        <Col xs={8} className="text-end">
                                            <Button variant="danger" className="m-1">
                                                <FontAwesomeIcon icon={faDownload} className="me-2" /> Download Resume
  </Button><Button variant="warning" className="m-1">
                                                <FontAwesomeIcon icon={faShareAlt} className="me-2" /> Share Profile
  </Button><Button variant="success" className="m-1">
                                                <FontAwesomeIcon icon={faPrint} className="me-2" /> Contact
  </Button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};
