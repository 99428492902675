
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faEye, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Image, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { Routes } from "../routes";
// import Api from '../../src/service/api';
import Api from '../../src/service/api';
import BgImage from "../../src/assets/img/illustrations/signin.svg";
import Profile3 from "../../src/assets/img/team/profile-picture-3.jpg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
  class loginpage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        email: "",
        emailErrorText: "",
        password: "",
        passwordErrorText: ""
      };
  
    }
    // componentDidMount() {
    // }
    onChangeOfemail = (event) => {
      console.log(event.target.value)
      let emailVal = event.target.value;
      if (emailVal === "") {
        this.setState({
          emailErrorText: "Enter Email",
          isValid: false
        })
      }
      else {
        this.setState({
          emailErrorText: "",
          email: event.target.value,
          isValid: true
        })
      }
    }
  
    onChangeofpassword = (event) => {
      let passwordlval = event.target.value;
      if (passwordlval === "") {
        this.setState({
          passwordErrorText: "Enter Password ",
          isValid: false
        })
      }
      else {
        this.setState({
          passwordErrorText: "",
          password: event.target.value,
          isValid: true
        })
      }
    }
    validateInput() {
      let passwordErrorText = '';
      let emailErrorText = ''
      let isValid = true;
  
      if (this.state.email.length === 0) {
        emailErrorText = 'Enter Email';
        isValid = false;
      }
      if (this.state.password.length === 0) {
        passwordErrorText = 'Enter Password';
        isValid = false;
      }
  
      this.setState({
        ...this.state,
        emailErrorText,
        passwordErrorText,
  
      })
      return isValid;
    }
    togglePasswordVisibility = () => {
    
    }
    passwordIconColor= () => {
      
    } 
        submitForm(event) {
      console.log(this.state)
      if (this.validateInput()) {
        let data = {
          "phone": this.state.email,
          "password": this.state.password,
        }
        Api.Login(data).then(
          res => {
            console.log(res.data, "res")
            if (res.data.error_code == 0) {
              toast.success('login Successfully', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              })
                window.location.href = ('#/dashboard/overview')
              event.preventDefault();
            } else {
              console.log("error",)
              toast.error('Something Went Wrong', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              })
            }
          }
  
        ).then(error => {
          console.log(error,"error")
        })
          .catch(error => {
            console.log(error,"error")
              toast.error('Something Went Wrong', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
          });
          
      }
    }
    render() {
  return (
    <main>
      <section className="vh-lg-100 bg-soft d-flex align-items-center my-4">
        <Container>
          <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border border-light rounded p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <div className="user-avatar mx-auto mb-3 border-dark p-2" style={{width:"7rem",height:"7rem",border:"2px solid #fff"}}>
                    <Image src={Profile3} className="rounded-circle" />
                  </div>
                  <h3 className="mb-3">Welcome To Social HR</h3>
                  <p className="text-gray">Hi Admin</p>
                </div>
                <Form className="mt-5" onSubmit={(e) => { e.preventDefault(); this.submitForm(e) }}>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Phone</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control onChange={this.onChangeOfemail} required type={"phone"} placeholder="Phone" />
                    </InputGroup>

                  </Form.Group>
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control onChange={this.onChangeofpassword} type={"password"} required placeholder="Password" />
                      <InputGroup.Text onClick={this.togglePasswordVisibility}>
                        <FontAwesomeIcon color={this.passwordIconColor} icon={faEye} />
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                <Button variant="primary" type="submit" className="w-100">
                    Submit
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
    }
};
export default loginpage
