
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faChartLine, faCloudUploadAlt, faEdit, faTrashAlt, faPlus, faRocket, faTasks, faUserShield, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Span, Dropdown, ButtonGroup, Card, Nav, Table, Form, FormLabel } from '@themesberg/react-bootstrap';
import { PageVisitsTable } from "../../components/Tables";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Api from '../../service/api';
class dashboard extends React.Component {
    constructor() {
        super();
        this.state = {
            readOnly: true,
            arrayOfDivs: [],
            divID: 1,
            arrayOfDivs1: [],
            divID1: 1,
            CourseList: [],
            JobFeilds: [],
            errorTextName: '',
            name: '',
            streamId: '',
            errorTextstreamId: '',
        }
    }
    componentDidMount() {
        this.getCourseList()
        this.getJobsFeildList()
    }
    getCourseList = () => {
        Api.getallCourse().then(
            res => {
                if (res.data.status == "success") {
                    console.log(res.data.data, "resss")
                    this.setState({
                        CourseList: res.data.data,
                    })
                    this.getJobsFeildList()
                } else {
                    console.log("error")
                }
            })
    }
    getJobsFeildList = () => {
        Api.getalljobfeilds().then(
            res => {
                if (res.data.status == "success") {
                    console.log(res.data.data, "resss")
                    this.setState({
                        JobFeilds: res.data.data,
                    })
                } else {
                    console.log("error")
                }
            })
    }
    getStreamByFeildList = (id) => {
        Api.getallstreambyfeildId(id).then(
            res => {
                console.log(res.data.data, "resList")
                if (res.data.status == "success") {
                    console.log(res.data.data, "resss")
                    this.setState({
                        StreamByFeildsId: res.data.data,
                    })
                } else {
                    console.log("error")
                }
            })
    }
    onChangeseletectOffeild = (event) => {
        console.log(event.target.value)
        let feildVal = event.target.value;
        if (feildVal === "") {
            this.setState({
                errorTextfieldId: "Enter Email",
                isValid: false
            })
        }
        else {
            this.setState({
                errorTextfieldId: "",
                SelectfieldId: event.target.value,
                isValid: true
            })
            this.getStreamByFeildList(event.target.value)
        }
    }
    onChangeseletectstream = (event) => {
        console.log(event.target.value)
        let feildVal = event.target.value;
        if (feildVal === "") {
            this.setState({
                errorTextfieldId: "Enter Email",
                isValid: false
            })
        }
        else {
            this.setState({
                errorTextfieldId: "",
                SelectfieldId: event.target.value,
                streamId: event.target.value,
                isValid: true
            })
            this.getCourseListBystremId(event.target.value)
        }
    }
    getCourseListBystremId (id) {
        Api.getallCourseStreamIddetails(id).then(
            res => {
                if (res.data.status == "success") {
                    console.log(res.data.data, "resss")
                    this.setState({
                        CourseList: res.data.data,
                    })
                    this.getJobsFeildList()
                } else {
                    console.log("error")
                }
            })
    }
    onchangeofCourseName = (event) => {
        console.log(event.target.value)
        let courseVal = event.target.value;
        if (courseVal === "") {
            this.setState({
                errorTextName: "Enter Email",
                isValid: false
            })
        }
        else {
            this.setState({
                errorTextName: "",
                name: event.target.value,
                isValid: true
            })
        }
    }
    onClickButton = (e) => {
        this.setState({ openModal: true })
    }
    onCloseModal = () => {
        this.setState({ openModal: false })
    }
    onClickButton1 = (e) => {
        console.log(e)

        this.setState({
            openModal1: true,
            editabledetails: e
        })
    }
    onCloseModal1 = () => {
        this.setState({ openModal1: false })
    }
    onClickButton2 = (e) => {
        this.setState({
            openModal2: true,
        })
    }
    onCloseModal2 = () => {
        this.setState({ openModal2: false })
    }
    submit = (e) => {
        confirmAlert({
            title: 'Delete',
            message: 'Are you sure want to Delete',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.deleteStream(e)
                },
                {
                    label: 'No',
                    onClick: () => alert('Click No')
                }
            ]
        });
    };
    deleteStream = (id) => {
        console.log(id)
        Api.deleteCourseDetails(id._id).then(
            res => {
                console.log(res.data, "res")
                if (res.data.status == "success") {
                    toast.success('Stream Details Deleted Successfully', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    this.onCloseModal()
                    window.location.reload();
                    this.getJobsFeildList()
                } else {
                    console.log("error",);
                    toast.error('Something Went Wrong', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }

        ).then(error => {
            console.log(error, "error")
        })
            .catch(error => {
                console.log(error, "error")
                toast.error('Something Went Wrong', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    }
    onClickofbulkupload = (e) => {
        console.log(e.target.files[0])
        this.setState({
            errorTextfile: "",
            file: e.target.files[0],
            isValid: true
        })
    }
    onClickexcelupload = (e) => {
        // console.log(e.target.files[0])
        var fd = new FormData;
        fd.set("file", this.state.file)
        Api.CoursebulkUpload(fd, this.state.SelectfieldId).then(
            res => {
                console.log(res.data.response, "res")
                if (res.data.response.responseStatus == 1) {
                    toast.success(res.data.response.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                } else {
                    console.log("error",)
                    toast.error('Something Went Wrong', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }

        ).then(error => {
            console.log(error, "error")
        })
            .catch(error => {
                console.log(error, "error")
                toast.error('Something Went Wrong', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    }
    validateInput() {
        let errorTextstreamId = '';
        let errorTextname = ''
        let isValid = true;

        if (this.state.name.length === 0) {
            errorTextname = 'Enter Email';
            isValid = false;
        }
        if (this.state.streamId === 0) {
            errorTextstreamId = 'Enter Password';
            isValid = false;
        }

        this.setState({
            ...this.state,
            errorTextname,
            errorTextstreamId,

        })
        return isValid;
    }
    submitForm(event) {
        console.log("sdkjf")
        if (this.validateInput()) {
            let data = {
                "name": this.state.name,
                "streamId": this.state.streamId,
                "fieldId": this.state.SelectfieldId
            }
            Api.addsingleCoursedetails(data).then(
                res => {
                    console.log(res.data, "res")
                    if (res.data.status == "success") {
                        toast.success('Stream Details added Successfully', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        })
                        this.onCloseModal()
                        window.location.reload();
                        event.preventDefault();
                    } else {
                        console.log("error",);
                        toast.error('Something Went Wrong', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        })
                    }
                }

            ).then(error => {
                console.log(error, "error")
            })
                .catch(error => {
                    console.log(error, "error")
                    toast.error('Something Went Wrong', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });

        }
    }

    onChangeOfeditName = (event) => {
        let nameVal = event.target.value;
        if (nameVal === "") {
            this.setState({
                onNameErrorText: "Enter Organization Name",
                isValid: false
            })
        }
        else {
            this.setState({
                onNameErrorText: "",
                name: event.target.value,
                isValid: true
            })
        }
    }
    getEditData = (e) => {
        console.log(e)
    }
    Submiteditdetails = () => {
        let data = {
            "name": this.state.name ? this.state.name : this.state.editabledetails.name,
            "streamId": this.state.editabledetails.streamId._id,
            "fieldId": this.state.editabledetails.fieldId
        }

        Api.updateCourseDetails(data, this.state.editabledetails._id).then(
            res => {
                console.log(res.data, "res")
                if (res.data.status == "success") {
                    toast.success('Stream Details Update Successfully', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    this.onCloseModal()
                    window.location.reload();
                    this.getJobsFeildList()
                } else {
                    console.log("error",);
                    toast.error('Something Went Wrong', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }

        ).then(error => {
            console.log(error, "error")
        })
            .catch(error => {
                console.log(error, "error")
                toast.error('Something Went Wrong', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
        console.log(data)
    }
    render() {
        return (
            <>
                <Card border="light" className="shadow-sm">
                    <Card.Header>
                        <Row className="align-items-center">
                            <Col>
                                <h5>Courses List</h5>
                            </Col>
                            <Col>
                                <Form>
                                    <Form.Group >
                                        <Form.Select defaultValue="0" onChange={this.onChangeseletectOffeild}>
                                            <option value="1">Selecte Feild List </option>
                                            {this.state.JobFeilds?.map((feild, index) => (<>   <option value={feild._id}>{feild.name}</option> </>))}
                                        </Form.Select>
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col>
                                <Form>
                                    <Form.Group >
                                        <Form.Select defaultValue="0" onChange={this.onChangeseletectstream}>
                                            <option value="1">Selecte Stream </option>
                                            {this.state.StreamByFeildsId?.map((stream, index) => (<>   <option value={stream._id}>{stream.name}</option> </>))}
                                        </Form.Select>
                                    </Form.Group>
                                </Form>
                            </Col>
                            {/* <Col></Col> */}
                            <Col></Col>
                            <Col className="text-end">
                                <React.Fragment>
                                    <div className="d-xl-flex align-items-center">
                                        <div className="file-field">
                                            <div className="d-flex justify-content-xl-center ms-xl-3">
                                                <Button onClick={() => this.onClickButton2()} variant="outline-success" className="m-1 d-flex">
                                                    {/* <input type="file" accept=".xlsx, .xls, .csv" /> */}
                                                    {/* <div className="d-md-block text-start">
                                                        <div className="text-gray small">
                                                        </div> */}
                                                    {/* </div> */}
                                                    Bulk Upload
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            </Col>
                            <Col className="text-end">
                                <Button variant="secondary" onClick={() => this.onClickButton()} size="sm">Add Course</Button>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Table responsive className="align-items-center table-flush">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Streams name</th>
                                <th scope="col">Course Name</th>
                                <th scope="col">Status</th>
                                <th scope="col">Active</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.CourseList.map((course, index) => (<>
                                <tr>
                                    <td>{course.streamId?.name}</td>
                                    <td>{course.name}</td>
                                    <td>Active</td>
                                    <td><FontAwesomeIcon onClick={() => this.onClickButton1(course)} icon={faEdit} cursor="pointer" className="me-2" /> <FontAwesomeIcon onClick={() => this.submit(course)} icon={faTrashAlt} className="me-2" /></td>
                                </tr>
                            </>
                            ))}
                        </tbody>
                    </Table>
                </Card>
                <div>
                    <Modal open={this.state.openModal} onClose={this.onCloseModal}>
                        <h4 className="shareprofile text-center">Add courses</h4>
                        <Form onSubmit={(e) => { e.preventDefault(); this.submitForm(e) }}>
                            <Form.Group className="mb-3">
                                <Form.Label> Select Feild List </Form.Label>
                                <Form.Select defaultValue="0" onChange={this.onChangeseletectOffeild}>
                                    <option value="1">Selecte Feild List </option>
                                    {this.state.JobFeilds?.map((feild, index) => (<>   <option value={feild._id}>{feild.name}</option> </>))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label> Select Stream  </Form.Label>
                                <Form.Select defaultValue="0" onChange={this.onChangeseletectstream}>
                                    <option value="1">Selecte Stream </option>
                                    {this.state.StreamByFeildsId?.map((stream, index) => (<>   <option value={stream._id}>{stream.name}</option> </>))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Course Name</Form.Label>
                                <Form.Control type="text" onChange={this.onchangeofCourseName} placeholder="Enter Course Name" />
                            </Form.Group>
                            <Col className="text-center">
                                <Button variant="secondary text-center" type="submit" size="md"> Submit</Button>
                            </Col>
                        </Form>
                    </Modal>
                </div>

                <div>
                    <Modal open={this.state.openModal1} onClose={this.onCloseModal1}>
                        <h4 className="shareprofile text-center">Edit Course</h4>
                        <Form>
                            {/* <Form.Group className="mb-3">
                                <Form.Label> Select Stream  </Form.Label>
                                <Form.Select>
                                    <option defaultValue>Select stream</option>
                                    <option>IT</option>
                                    <option>Marketing</option>
                                    <option>Sales</option>
                                    <option>Finance</option>
                                </Form.Select>
                            </Form.Group> */}
                            <Form.Group className="mb-3">
                                <Form.Label>Course Name</Form.Label>
                                <Form.Control type="text" defaultValue={this.state.editabledetails?.name ? this.state.editabledetails?.name : this.state.name} onChange={this.onChangeOfeditName} placeholder="Enter Course Name" />
                            </Form.Group>
                            {/* <Form.Group className="mb-3">
                                <Form.Label>Active</Form.Label>
                                <Form.Select>
                                    <option defaultValue>Select status</option>
                                    <option>Active</option>
                                    <option>In Active</option>
                                </Form.Select>
                            </Form.Group> */}
                            <Col className="text-center">
                                <Button variant="secondary text-center" onClick={this.Submiteditdetails} size="md"> Submit</Button>
                            </Col>
                        </Form>
                    </Modal>
                    <ToastContainer />
                </div>
                <div>
                    <Modal open={this.state.openModal2} onClose={this.onCloseModal2}>
                        <h4 className="shareprofile text-center">Bulk Upload Course</h4>
                        <Form>
                            <Form.Group >
                                <Form.Label>Select Feild </Form.Label>
                                <Form.Select defaultValue="0" onChange={this.onChangeseletectOffeild}>
                                    <option value="1">Selecte Feild List </option>
                                    {this.state.JobFeilds?.map((feild, index) => (<>   <option value={feild._id}>{feild.name}</option> </>))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Bulk Upload</Form.Label>
                                <Form.Control type="file" accept=".xlsx, .xls, .csv" onChange={this.onClickofbulkupload}></Form.Control>
                                {/* <Button variant="outline-success" className="m-1 d-flex">
                                    <input type="file" accept=".xlsx, .xls, .csv" />
                                    <div className="d-md-block text-start">
                                        <div className="text-gray small">Bulk Upload</div>
                                    </div>
                                </Button> */}
                            </Form.Group>
                            <Col className="text-center">
                                <Button variant="secondary text-center" onClick={this.onClickexcelupload} size="md"> Submit</Button>
                            </Col>
                        </Form>
                    </Modal>
                    <ToastContainer />
                </div>
            </>
        );
    };
};
export default dashboard;